import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['../patient-profile.component.scss','./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {

  @Input('id') id:any;
  @Input('innerpage') innerpage:any;

  data:any = [];
  files:any = {
    status:0,
    files:[]
  }
  limit:any = 3;
  filesLimit:any = 6;
  showPdf = 0;
  pdfFiles = [];
  selectedPdf:any = ""
  pdfIndex = 0;
  images = ['../../../../assets/img/loader.jpg'];  
  uploadFiles:any[] = [];
  uploadFolderTitle:any = "";
  uploadingInProgress:boolean = false;

  constructor(private dataService: DataService, private ngxService: NgxUiLoaderService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.loadData();
    this.loadFiles();

    $("body").on("click", ".drag-and-drop", function(){
      $("#file-upload-ctrl").trigger("click");
    });
  }

  loadData(){
    this.dataService.getClientAssessments(this.id, this.limit ).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

  loadFiles(){
    this.dataService.getClientFiles(this.id, this.filesLimit ).subscribe( (data:any = {status:0, files:[]} )=> {
      
      if(data.status == 0){
        this.files = data;
      }
      
      
    });
  }

  openViewer(file){
    file.load_flag = 1;

    this.dataService.getDocumentPages(file.id).subscribe( (data: any[] )=> {
      file.load_flag = 0;
      //@ts-ignore
      if (data.status == 0) {
        
        //@ts-ignore   
        if (data.extn === 'jpg' || data.extn === 'jpeg' || data.extn === 'png' ) {
          $('#viewer').addClass('show');
          //@ts-ignore
          this.images = data.files;
          
        } else {
          //@ts-ignore
          //window.open(data.files[0]);
          //@ts-ignore
          this.pdfFiles = data.files;
          this.selectedPdf =  this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfFiles[0]);
          //this.selectedPdf = this.sanitizer.sanitize(5, this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfFiles[0]));
          this.showPdf = 1;
          console.log("first file", this.pdfFiles[0]);
          
          

        }

        
      }
      
      
    } );

  }

  closeViewer(){
    $('#viewer').removeClass('show');
    this.images = ['../../../../assets/img/loader.jpg'];  
  }

  onFileDropped(e){
    for(const item of e){
      this.uploadFiles.push(item)
    }
  }

  onFileSelect(e){
    
    for(const item of e.target.files){
      this.uploadFiles.push(item);
    }
  }

  //formatbytes for file upload;

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  removeUploadFile(index){
    this.uploadFiles.splice(index, 1);
  }

  uploadSelectedFiles(){

    if(this.uploadFolderTitle.length > 0){
      const formData = new FormData();
      if(this.uploadFiles.length){
        for(let i=0 ; i < this.uploadFiles.length ; i++){
          formData.append('file[]', this.uploadFiles[i], this.uploadFiles[i].name);
        }

        formData.append("file_title", this.uploadFolderTitle);
        formData.append("patient_id", this.id);
        this.uploadingInProgress = true;
        this.dataService.postFile(formData).subscribe( (res:any = {status:0, msg:""}) => {
          this.uploadingInProgress = false;
          if(res.status == 0) {
            
            //reload files;
            alert("Upload success");
            this.loadFiles();
            this.uploadFolderTitle = "";
            this.uploadFiles = [];

            //send Nabidh
            if(res.is_nabidh == 1){

              let data = {patient_id:this.id, fileKeys:res.keys};

              this.dataService.sendNabidhFile(data).subscribe( (res:any) => {
                console.log(res);
              });
            }
            
          } else {
            alert(res.msg);
          }
        
        });

      }
    } else {
      alert("Enter a name for the folder");
    }

    
  }

  deleteFile(id){
    let data = {id:id};
    let cnf = confirm("This will permanently delete the file. Do you wish to continue?");

    if(cnf){
      this.dataService.postClientDeleteFile(data).subscribe( (res:any) => {
        
        if(res.status == 0) {
          alert(res.msg);
          this.loadFiles();
        } else {
          alert(res.msg);
        }
  
  
      });
    }
    
  }

  closePdfViewer(){
    this.showPdf = 0;
  }
  navigatePdf(direction){
    if(direction == 1){
      this.pdfIndex += 1;
    } else {
      this.pdfIndex -= 1;
    }

    this.selectedPdf =  this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfFiles[this.pdfIndex]);

  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-patient-goals',
  templateUrl: './patient-goals.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss']
})
export class PatientGoalsComponent implements OnInit {

  id = '0';
  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-therapy-package',
  templateUrl: './therapy-package.component.html',
  styleUrls: ['../patient-profile.component.scss', './therapy-package.component.scss']
})
export class TherapyPackageComponent implements OnInit {

  

  @Input('id') id:any;
  therapyPackages:any = {}
  patientPackage:any =  {
    is_active:0,
    name:"",
    from:"",
    valid_until:""
  };

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadPackages();
    this.loadPatientPackage();
  }

  loadPackages(){
    this.dataService.getTherapyPackages().subscribe( (data: any ) =>{
          this.therapyPackages = data;
          
          
      });
  }

  loadPatientPackage(){
    this.dataService.getPatientPackage(this.id).subscribe( (data: any ) =>{
          if(data.status == 0){
            this.patientPackage = data.package;
          } else {
            this.patientPackage = {
              is_active:0,
              name:"",
              from:"",
              valid_until:""
            };
          }
    });
  }

  submitAssignPackage(data){
		data.patient_id = this.id;
		
		$('#btn-assign-package').attr('disabled', 'true');
		$('#btn-assign-package').text('Submitting');

    this.dataService.postAssignTherapyPackage(data).subscribe( (res:any) => {
      $('#btn-assign-package').removeAttr('disabled');
      $('#btn-assign-package').text('Assign');
      if(res.status == 0) {
        $(".btn-close-modal").trigger("click");
        this.loadPatientPackage();
        
      } else {
        alert(res.msg);
      }
    });
	}

  removePackageAssignment(){
    let cnf = confirm("Do you really want to remove this package?");

    if(cnf){
      let data = {
        id:this.patientPackage.id
      }
      this.dataService.postRemoveTherapyPackageAssignment(data).subscribe( (res:any) => {
        
        if(res.status == 0) {
          this.loadPatientPackage();
        } else {
          alert(res.msg);
        }
      });
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {
    status:0,
    appointments:0,
    patients:0,
    discharges:0,
    period_discharges:0,
    total_patients:0,
    today:"",
  };
  period = 1;
  date1 ="";
  date2 ="";
  roleId:any = 0;
  selectedConsultant = 0;
  consultantName = "";
  selectedDept = 0;
  deptName = "";
  loading = 0;
  consultants:any = [];
  depts:any = [];
  selectedBranch = 0;
  branches:any = [];

  ageData = {
  labels: ['1-10 Years of age', '10-20 Years of age', '20-40 Years of age', '40-60 Years of age','60+ Years of age'],
  datasets: [{

  backgroundColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A', '#43A2C9'],
  borderColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A', '#43A2C9'],
  data:[3200, 4300, 1760, 520, 2720],
  }],

  };

  ngOnInit() {
    this.roleId = localStorage.getItem("role_id");
    this.loadData();
    this.loadBranches();
  }

  loadData(){
    this.loading = 1;
    console.log("loading data", this.loading);
    if(this.roleId == '1' || this.roleId == '11' ){
      this.dataService.getAnalyticsAdmin(this.period, this.date1, this.date2, this.selectedConsultant, this.selectedDept, this.selectedBranch).subscribe( (data: any[] )=> {
        this.data = data;
        console.log("data", this.data);
        this.loading = 0;
        console.log("admin");
        //this.plotGraph(this.ageData, "age-graph");  
      });
    } else {
      this.dataService.getAnalytics(this.period, this.date1, this.date2).subscribe( (data: any[] )=> {
        this.data = data;
        this.loading = 0;
        //this.plotGraph(this.ageData, "age-graph");  
      });
    }

    
  }

  changePeriod(period) {
    this.period  = period;
    if(period != 4){
      this.loadData();
    }
    
  }

  customDateFilter(){
    
    if(this.date1.length < 4 || this.date2.length < 4  ) {
      alert("Need to select both start date and end date");
    } else {
      this.loadData();
    }

  }

  clearAdminFilters(){
    this.selectedConsultant = 0;
    this.consultantName = "";
    this.selectedDept = 0;
    this.deptName = "";
    this.loadData();
    $("#therapistAc").val("");
  }

  getConsultant(term){
    
    this.dataService.getTherapistsAc(term, this.selectedBranch).subscribe( (data: any[] ) =>{
     this.consultants = data; 
     
    });
  }

  setConsultant(term){
    let filtered = this.consultants.filter((consultant)=>consultant.consultant_name.toLowerCase().includes(term.toLowerCase()));
    this.selectedConsultant = filtered[0].id;
    this.loadData();
    
  }

  getDept(term){
    
    this.dataService.getDeptAc(term, this.selectedBranch).subscribe( (data: any[] ) =>{
     this.depts = data; 
     
    });
  }

  setDept(term){
    let filtered = this.depts.filter((dept)=>dept.department_name.toLowerCase().includes(term.toLowerCase()));
    this.selectedDept = filtered[0].id;
    this.loadData();
    
  }

  loadBranches(){
    this.dataService.getBranches().subscribe( (data: any[] ) =>{
      this.branches = data; 
      console.log(data);
      
     });
  }

  filterBranch(branch){
    this.selectedBranch = branch;
    this.loadData();
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-profilebox',
  templateUrl: './profilebox.component.html',
  styleUrls: ['../patient-profile.component.scss', './profilebox.component.scss']
})
export class ProfileboxComponent implements OnInit {

  @Input('id') id:any;

  client:any = {name:"", reg_no:"", address:"", phone:"", email:"", last_visit:"", age:"", dob:"", gender:"", class_std:""}
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadClient();
  }

  loadClient(){
    this.dataService.getClientProfile(this.id).subscribe( (data: any[] )=> {
      this.client = data;
      
    });
  }

  loadEdPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data:any )=> {
      this.dataService.edPatient = data;
      
    });
  }

}

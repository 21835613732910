import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-patientoverview',
  templateUrl: './patientoverview.component.html',
  styleUrls: ['../patient-profile.component.scss','./patientoverview.component.scss']
})
export class PatientoverviewComponent implements OnInit {

  @Input('id') id:any;
  data:any = {status:0, activities:[]};
  dept:any = "";
  consultant:any = "";
  limit:any = 3;
  selectedRemark:any = {
    category:"", 
    title:"", 
    consultant:"", 
    date:"",
    dept:"",
    tag:"",
    item:{
      id:0,
      title:"",
      contents:"",
      
    }
  };
  

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadOverview();
    
  }

  

  loadOverview(){
    this.dataService.getClientOverview(this.id, this.dept, this.consultant, this.limit ).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

  submitNotes(data){
    
    data.patient_id = this.id;
    //data.date = $('#note-date').val();
    $('#btn-remarks').attr('disabled', 'true');
    $('#btn-remarks').text('Submitting');
    
    this.dataService.postNotes(data).subscribe( (res:any) => {
      if(res.status == 0) {
        alert(res.msg);
        //$('#modalRemarks').modal('hide');
        $('.btn-close-modal').trigger("click");
        $('#btn-remarks').text('Submit');
        this.loadOverview();
      } else {
        alert(res.msg);
      }


    });

    
    
  }

  loadRemarks(item){
    this.selectedRemark = item;
    $("#modalViewRemarks").modal("show");
    console.log(item);
  }

  

}



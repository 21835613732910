import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss']
})
export class PatientsListComponent implements OnInit {
	
	
  	constructor(private dataService: DataService) { }
  	data = [];

  	role_id = '';
  	key:any;
  	limit = 20;
  	search = "";
  	department= 0;
  	departments = [];
  	edit = { 'treatment':'' };
  	role = localStorage.getItem('role_id');
  	
	ngOnInit() {

	}

	

}

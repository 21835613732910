import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vchat',
  templateUrl: './vchat.component.html',
  styleUrls: ['./vchat.component.scss']
})
export class VchatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

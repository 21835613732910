export const environment = {
  production: false,
  SOCKET_ENDPOINT: 'https://chat.metanoa.ai',
  //SOCKET_ENDPOINT: 'http://localhost:5000',
  //SOCKET_ENDPOINT: 'http://43.205.38.43:5000',
  //SOCKET_ENDPOINT: 'https://chat-dev.metanoa.ai:5000',
  OT_KEY: '46803104',
  REST_API_SERVER: 'http://localhost/metanoa-api/frontend/web/?r=',
  HT_API: 'https://ht-api.metanoa.ai/?r=',
  AGORA_KEY: 'a8197a5646734a0690c1bbc727286824',
};
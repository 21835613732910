import { Component, OnInit, NgZone  } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.scss']
})
export class GenerateBillComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private ngZone: NgZone) { }

  patient:any = {patient_id:0, patient_name:"", phone:""};
  id:any = 0;
  appointmentId:any = 0;
  billContents:any = [
    {description:"", rate:"", qty:1, amount:"", is_therapy:0, therapist:0, duration:"", label:0},
  ];
  totalAmt:any = 0;
  therapists:any = [];
  selectedTherapist:any = 0;
  billHeads:any = [];
  billHeadType:any = 1;
  others:any = [];
  labels:any = [];
  selectedLabel:any = 0;
  settings:any = {allow_vat:0}
  vatAdded:any = false;
  vatRate:any = 0;
  

  ngOnInit() {
    this.id = (this.route.snapshot.paramMap.get('id') != null) ? this.route.snapshot.paramMap.get('id') : 0;
    this.appointmentId = (this.route.snapshot.paramMap.get('appointmentId') != null) ? this.route.snapshot.paramMap.get('appointmentId') : 0;
    this.loadPatient();
    this.loadAppointment();
    this.loadTherapists();
    this.loadBillHeads();
    this.loadBillLabels();
    this.loadSettings();
    let context = this;
    //@ts-ignore
    if(this.dataService.localData.roleId == 3 || this.dataService.localData.roleId == 4){
      this.selectedTherapist = this.dataService.localData.userId;
    }

    if(this.dataService.localData.billContents.length > 0){
      this.billContents = this.dataService.localData.billContents;
      this.billContents.forEach((elem, index) => {
        this.others.push(index);
        
      });

      this.calculateBillTotal();
    }

    //Autocomplete

    $('body').on('focus', '#inputPatient', function(){
      let moi = $(this);
      let branch = (localStorage.getItem("branch") !== null ) ? JSON.parse(localStorage.getItem("branch")) : {id:0, name:""};
      //@ts-ignore
      moi.autocomplete({
        //appendTo: '#addAppointment',
        source: function(request, response) {
            $.ajax({
              url:context.dataService.REST_API_SERVER + 'patient/autocomplete',
              type:'GET',
              dataType:'json',
              data: {term: request.term, branch:branch.id},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {value: element['value'] , op_number: element['op_number'], patient_id: element['patient_id'], patient_name: element['patient_name'], phone: element['phone']};
                });
                response(array);
    
              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.patient_name);
              context.ngZone.run(() => {
                context.router.navigate(['/dashboard/generate-bill/'+ui.item.patient_id]);
              });

              
              
              return false;
          }
      });
    });
    


  }

  loadPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data: any = {status:0} )=> {
      if(data.status == 0){
        this.patient = data;
      }
      
    });
  }

  loadBillLabels(){
    this.dataService.getBillLabels().subscribe( (data: any  )=> {
      this.labels = data
      if(data.length > 0){
        //this.selectedLabel = data[0].id;
      }
      
    });
  }

  loadSettings(){
    this.dataService.getBillSettings().subscribe( (data: any  )=> {
      
      if(data.status == 0){
        console.log(data)
        this.settings = data
      }
      
      
      
      
    });
  }

  loadAppointment(){

    if(this.appointmentId != 0){
      this.dataService.getBillingAppointment(this.appointmentId).subscribe( (data: any = {status:0} )=> {
        if(data.status == 0){
          this.selectedTherapist = data.therapist_id;
        }
        
        
      });
    }

    
  }

  loadTherapists(){
    this.dataService.getTherapists(0, 1).subscribe( (data: any = {status:0} )=> {
      if(data.status == 0){
        this.therapists = data;
        console.log(data);
      }
      
      
    });
  }

  addBillRow(){
    this.billContents.push({description:"", rate:"", qty:1, amount:"", is_therapy:0, therapist:0, duration:"", label:0});
  }

  removeBillRow(index){
    let item = this.billContents[index];
    if (item && 'is_vat' in item && item.is_vat === 1) {
      this.vatAdded = false;
    } 
    this.billContents.splice(index, 1);
    this.calculateBillTotal();
  }

  generateBill(){
    $('#btn-submit').attr('disabled', 'true');
    $('#btn-submit').text('Submitting');
    var data = {
      patient_id: this.id,
      billcontents:this.billContents,
      therapist:this.selectedTherapist,
      appointmentId: this.appointmentId,
      label:this.selectedLabel
    };

    

    if(this.billContents.length > 0){
      this.dataService.postBill(data).subscribe( (res:any) => {
        $('#btn-submit').removeAttr('disabled');
        $('#btn-submit').text('generate Bll');

        if(res.status == 0) {
          alert(res.msg);
          this.dataService.localData.billContents = [];
          this.router.navigate(['/dashboard/bill/'+res.bill_id]);
          
          
        } else {
          alert(res.msg);
        }

      }); 
    }

  }
  
  calculateAmount(index){
    var rate = this.billContents[index].rate;
    var qty = this.billContents[index].qty;
    var amt = 0;
    var total = 0;

    if(!isNaN(rate) && !isNaN(qty) ){
      amt = rate * qty;
      this.billContents[index].amount = amt;

      
    } else {
      amt = 0;
    }

    this.billContents.forEach(element => {
      
      if(!isNaN(element.amount) && element.amount != null && element.amount != ""){
        total += element.amount;
        
      }

      
    });
  
    this.totalAmt = total;

    
  }

  toggleTherapy(index){
    if(this.billContents[index].is_therapy == 1 ) {
      this.billContents[index].is_therapy = 0;
    } else {
      this.billContents[index].is_therapy = 1;
    }
  }

  selectBcTherapist(index, id){
    if(id != 0){
      this.billContents[index].is_therapy = 1;
    }
  }

  loadBillHeads(){
    this.dataService.getBillHeads(1, this.billHeadType).subscribe( (data: any = {status:0} )=> {
      this.billHeads = data;      
    });
  }

  setBillHead(i, j){
    
    if(this.billHeads[j].id == 'others'){
      this.others.push(i);
      $("input[name=description"+i+"]").focus();
    } else {
      this.billContents[i].description = this.billHeads[j].title;
      this.billContents[i].rate = this.billHeads[j].amount;
      this.billContents[i].is_therapy = this.billHeads[j].is_therapy;
      this.billContents[i].label = this.billHeads[j].label_id;
      this.calculateAmount(i);
    }

  }

  removeFreeText(i){
    if(this.others.indexOf(i) >= 0 ){
      this.others.splice(this.others.indexOf(i), 1);
    }
  }

  applyVAT(){
   
    if(!this.vatAdded){

      if(this.vatRate > 0){
        let total = 0;
    
        this.billContents.forEach(row => {
            total += row.amount;
        });
    
        let vatAmount = total * (this.vatRate/100);
    
        this.billContents.push({
          description: "VAT @ " + this.vatRate + "%",
          rate: vatAmount,
          qty: 1,
          amount: vatAmount,
          is_therapy:0,
          therapist:0,
          duration:"",
          label:0,
          is_vat:1
        })

        this.others.push(this.billContents.length-1);
        console.log(this.others);
        this.calculateBillTotal();

        this.vatAdded = true;
      }  else {
        alert("VAT Rate can't be 0")
      }

      
    } else {
      alert("VAT has already been applied");
    }



  }

  calculateBillTotal() {
    let total = 0;
    this.billContents.forEach(element => {
      // Convert element.amount to a number if it's valid, otherwise skip it
      let amount = parseFloat(element.amount);
  
      if (!isNaN(amount)) {
        total += amount;  // Add to total only if it's a valid number
      }
    });
  
    this.totalAmt = total;
    console.log("total>>>>>>>>>>>>>", total);
  }
  

}

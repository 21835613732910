import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private formBuilder: FormBuilder) { }

  uploadForm: FormGroup;
  fileUpload = {status: '', message: '', filePath: ''};
  upFiles = [];

  ngOnInit() {
  	this.uploadForm = this.formBuilder.group({
      file: [''],
      title: "",
      details:""
    });
  }



  onFileSelect(event) {

    
    if(event.target.files.length > 0) {
      
      
      this.uploadForm.get('file').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }

    }
    

  }

  onTitle(val) {
    this.uploadForm.get('title').setValue(val);
  }

  onDetails(val) {
    this.uploadForm.get('details').setValue(val);
  }

  submitIssue(){
  	const formData = new FormData();
  	if(this.upFiles.length){
       for(let i=0 ; i < this.upFiles.length ; i++)
         formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
    }

    formData.append('title', this.uploadForm.get('title').value);
    formData.append('details', this.uploadForm.get('details').value);

    $('#btn-send-report').attr('disabled', 'true');
    
    this.dataService.postReportIssue(formData).subscribe( (res:any) => {
    	$('#btn-send-report').removeAttr('disabled');  
    
      	alert(res.msg);
        //@ts-ignore
        document.getElementById("formIssue").reset(); 

    });

    
  }

}

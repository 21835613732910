import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute) { }

  id:any  = 0;

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
  }

}

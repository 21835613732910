import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss']
})
export class ReviewFormComponent implements OnInit {

  id = '0';
  formid = '0';
  subid = '0';
  
  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.formid = this.route.snapshot.paramMap.get('formid');
    this.subid = this.route.snapshot.paramMap.get('subid');
    console.log(this.subid);
    
  }

  

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['../../dashboard/patient-profile/patient-profile.component.scss', './info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input('id') id:any;
  client:any = {name:"", reg_no:"", address:"", phone:"", email:"", last_visit:"", age:"", dob:"", gender:"", class_std:"", settings:{}}
  constructor(private dataService: DataService, private router:Router) { }

  ngOnInit() {
    this.loadClient();
  }

  loadClient(){
    this.dataService.getClientProfile(this.id).subscribe( (data: any[] )=> {
      this.client = data;
      
    });
  }

  loadEdPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data:any )=> {
      this.dataService.edPatient = data;
      
    });
  }

  deletePatient(patient){
    let cnf = confirm("Do you really want to delete " + patient.name + "? You can't undo this later.");
  
    if(cnf){
      let data = {id:patient.id}
      this.dataService.postDeletePatient(data).subscribe( (res: any) => {
        if(res.status == 0){
          this.router.navigate(['/dashboard/patients/branch']);
          
        } else {
          alert(res.msg);
        }
  
      });
    }
  
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-client-basic-profile',
  templateUrl: './client-basic-profile.component.html',
  styleUrls: ['./client-basic-profile.component.scss']
})
export class ClientBasicProfileComponent implements OnInit {

  constructor(private dataService: DataService) { }
  @Input('id') id:any;

  data = {
    patient_id:0,
    op_number:"",
    patient_name:"",
    phone:"",
    phone2:"",
    gender:"",
    dob:"",
    member_since:"",
    orgId:0,
    address:"",
    city:"",
    po:"",
    state:"",
    pin:"",
    country:"",
    nationality:"",
    employment:"",
    monthly_income:"",
    languages:[],
    guardian:"",
    guardian_contact:"",
    id_type:"",
    id_number:"",
    referred_by:"",
    referral_reason:"",
    recommendations:"",
    class_std:""

  };

  patientSearchData:any = [];
  mergePatient:any = {id:0, name:""}

  insurance:any = {
    id:0,
    health_plan_id:"",
    insurance_company_id:"",
    insurance_company_name:"",
    policy_no:""
  }

  ngOnInit() {
    this.loadPatient();
    this.loadInsurance();
  }

  loadPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data:any )=> {
      this.data = data;
      
    });
  }

  loadInsurance(){
    this.dataService.getPatientInsurance(this.id).subscribe( (data:any )=> {
      if(data.status == 0){
        this.insurance = data.insurance;
      }
      
    });
  }

  //Autocomplete - patient search;
  selectPatientEvent(event){
    this.mergePatient.id = event.patient_id;
    this.mergePatient.name = event.patient_name;
    
  }

  
  onClearPatientSearch(event){
    this.mergePatient.id = 0;
    this.mergePatient.name = "";
    
  }
  
  onChangePatientSearch(event){
    
    this.dataService.getPatientAc(event).subscribe( (data: any )=> {
  		this.patientSearchData = data;
  		
  	} );

  }
  mergePatients(){
    let data = {patient_id:this.data.patient_id, duplicate_patient_id:this.mergePatient.id};
    this.dataService.postNabidhMerge(data).subscribe( (res:any) => {
      alert(res.msg);
      if(res.status == 0){
        $(".btn-close-modal").trigger("click");
      }
     
    });
  }

  submitInsurance(data){
    data.patient_id = this.data.patient_id;
    data.id = this.insurance.id;
    this.dataService.postPatientInsurance(data).subscribe( (res:any) => {
      if(res.status == 0){
        $(".btn-close-modal").trigger("click");
        this.loadInsurance();
      } else {
        alert(res.msg);
      }
     
    });
  }

  dischargePatient(){
    let cnf = confirm("Confirm whether you want to discharge this patient.");

    if(cnf){
      let data = {
        patient_id:this.data.patient_id
      }

      this.dataService.postPatientDischarge(data).subscribe( (res:any) => {
        if(res.status == 0){
          alert(res.msg);
        } else {
          alert(res.msg);
        }
       
      });

    }

  }

  cancelPatientAdmission(){
    let cnf = confirm("Confirm whether you want to discharge this patient.");

    if(cnf){
      let data = {
        patient_id:this.data.patient_id
      }

      this.dataService.postPatientCancelAdmission(data).subscribe( (res:any) => {
        if(res.status == 0){
          alert(res.msg);
        } else {
          alert(res.msg);
        }
       
      });

    }

  }

}

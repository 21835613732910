import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-site-analytics',
  templateUrl: './site-analytics.component.html',
  styleUrls: ['../../../super-analytics/index/index.component.scss', './site-analytics.component.scss']
})
export class SiteAnalyticsComponent implements OnInit {

  constructor(private dataService: DataService) { }
  
  date:any = new Date();
  branches:any = [];
  selectedBranch = {id:0, name:"All branches"};
  depts:any = [];
  selectedDept:any = {id:0, name:"All departments"};
  consultants:any = [];
  selectedConsultant:any = {id:0, name:"All consultants"};
  periods:any =  [
    "Month",
    "Year",
    "Today",
    "Custom"
  ];
  date1:any = "";
  date2:any = "";
  selectedPeriod:any = "";
  loading = 0;

  data:any = {
    revenue:0,
    revenue_last:0,
    device_sale:"",
    referral_revenue:"",

  };

  saData:any = {
    clinics:0,
    users:0,
    patients:0,
    parents:0,
    appointments_day:0
  };
  liveSessions:any = 12;

  billData:any  = [

  ];

  demographics:any = {
    status:0,
    age:[],
    gender:[]
  }
  showLast:any = true;
  currency:any = "";

  ngOnInit() {
    
    this.selectedPeriod = this.periods[0];
    
    this.loadBranches();
    this.loadDepartments();
    this.loadConsultants();

    this.loadAnalytics();

    
    let context = this;
    setTimeout(
      function() {
        context.liveSessions++;
      }, 10000);

    setTimeout(
        function() {
          context.saData.parents++;
      }, 5000);

  }

  loadAnalytics(){
    this.loadData();
    this.loadBillData();
    this.loadDemographics();
    this.loadSAData();
  }
  

  loadData(){
    this.dataService.getAnalyticsV2(this.selectedPeriod, this.selectedBranch.id, this.selectedDept.id, this.selectedConsultant.id, this.date1, this.date2).subscribe( (data: any ) =>{
      this.data = data; 
      console.log("data", data);

      if(data.status == 0){
        this.currency = data.currency;
      }

    });
  }

  loadSAData(){
    this.dataService.getSuperAnalytics().subscribe( (data: any )=> {
			this.saData = data;
		});
  }

  loadBillData(){
    this.dataService.getAnalyticsBillData(this.selectedPeriod, this.selectedBranch.id, this.selectedDept.id, this.selectedConsultant.id, this.date1, this.date2).subscribe( (data: any ) =>{
      this.billData = data; 
      console.log(data);
    });
  }

  loadDemographics(){
    this.dataService.getAnalyticsDemographics(this.selectedPeriod, this.selectedBranch.id, this.selectedDept.id, this.selectedConsultant.id, this.date1, this.date2).subscribe( (data: any ) =>{
      this.demographics = data; 
      console.log(data);
    });
  }

  loadBranches(){
    this.dataService.getBranches().subscribe( (data: any[] ) =>{
      this.branches = data; 
      
    });
  }

  selectBranch(branch){
    this.selectedBranch = branch;
    this.loadAnalytics();
    this.loadConsultants();
  }

  loadDepartments(){
    this.dataService.getDepts().subscribe( (data: any ) =>{
      this.depts = data.departments; 
      
      
     });
  }

  selectDept(dept){
    this.selectedDept = dept;
    
    this.loadConsultants();
    this.loadAnalytics();
  }

  loadConsultants(){
    this.dataService.getTherapists(this.selectedBranch.id, 0, this.selectedDept.id).subscribe( (data: any ) =>{
      this.consultants = data.therapists; 
      
      
     }); 
  }

  selectConsultant(c){
    this.selectedConsultant = c;
    console.log(c);
    this.loadAnalytics();
  }

  selectPeriod(period){
    this.selectedPeriod = period;

    if(period == "Custom"){
      this.date1 = "";
      this.date2 = "";
      this.showLast = false;
    } else {
      this.loadAnalytics();
    }

  }

  customDate(){
    if(this.validateDate(this.date1)){
      this.loadData();
    } 
  }

  validateDate(dateString){
    
    const dateRegex = /^20\d{2}-\d{2}-\d{2}$/;

    if (dateRegex.test(dateString)) {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['../patient-profile.component.scss', './goals.component.scss']
})
export class GoalsComponent implements OnInit {

  @Input('id') id:any;

  data:any = {status:0, goals:[]};
  goals = [];
  limit:any = 3;
  selectedAssessment:any = {id:0, title:""}
  selectedGoal:any = {id:0};
  activities:any = {};
  selectedEv:any = {};
  evVal:any = 0;
  evPrompt:any = {status:0, level:0};
  
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadGoals();
  }

  /*loadData(){
    this.dataService.getClientGoals(this.id, this.limit ).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  } */

  loadGoals(assessment_id = this.selectedAssessment.id){
    this.dataService.getAssessmentGoalsActivities(this.id, assessment_id, this.limit).subscribe( (data:any = {goals:[]} )=> {
  		this.goals = data.goals;
      
    });
  }

  selectGoal(goal){
    this.evVal = 0;
    this.selectedGoal = goal;
    this.loadGoalEv(goal.goal_id);
  }

  loadActivities(goal_id) {
  	this.dataService.getGaActivities(goal_id, this.id).subscribe( (data: any[] )=> {
  		
      	this.activities = data;
      
    });
  }

  loadGoalEv(goal){
    this.dataService.getGoalEv(goal, this.id).subscribe( (data: any[] )=> {
      this.selectedEv = data;
      
    });   
  }

  setEvVal(val){
    this.evVal = val;
  }

  setPrompt(param, val){
    
    if(param == 'status'){
      if(val == 'on') {
        val = 1;
      } else {
        val = 0;
      }
    }

    this.evPrompt[param] = val;
    console.log("ev", this.evPrompt);

  }

  markTrial(){
    let data = {
      patient_id:this.id,
      goal_id: this.selectedEv.goal_id,
      activity_id: this.selectedEv.activity_id,
      score: this.evVal,
      evPrompt: this.evPrompt
    };

    this.dataService.postGaEvaluation(data).subscribe( (res:any) => {
      
      
      if (res.status == 0) {
        //$('#modal-evaluate').modal('hide');
        //$('.btn-cancel').click();
        this.selectedGoal = {id:0};
        //@ts-ignore
        //this.loadGoals(this.user.phone);
        this.loadGoals(0);

      } else {
        alert(res.msg);
      }


    });

  }

  closeEv(){
    this.selectedGoal = {id:0};
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-profileheader',
  templateUrl: './profileheader.component.html',
  styleUrls: ['../patient-profile.component.scss','./profileheader.component.scss']
})
export class ProfileheaderComponent implements OnInit {

  constructor(private dataService: DataService) { }
  data:any = {name:"", role:"", single_user:"", department:"", branch:""}
  @Input('id') id:any;
  regExpand:any = 0;
  notifications:any = [];

  ngOnInit() {
    this.loadData();
  }

  loadData(){
    this.dataService.headBar().subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-goal-tracker',
  templateUrl: './goal-tracker.component.html',
  styleUrls: ['../../patient-profile/patient-profile.component.scss', './goal-tracker.component.scss']
})
export class GoalTrackerComponent implements OnInit {

  @Input('id') id:any;
  @Input('mode') mode:any;
  goals = [];
  limit:any = 0;
  selectedAssessment:any = {id:0, title:""}
  selectedGoal:any = {id:0};
  selectedSubgoal:any = {id:0};
  selectedTask:any = {id:0, task_title:""}
  activities:any = {};
  selectedEv:any = {};
  evVal:any = 0;
  evPrompt:any = {status:0, level:0};
  evNotes:any = "";
  evalHistory:any = {status:0, activity:"", trialdata:[]}
  departments:any = {departments:[], mydept:"", mydeptId:0}
  selectedDept:any = {id:0, name:"All departments"}
  selectedAll:any = 0;
  addSgToLib:any = false;
  addTaskToLib:any = false;
  selectedSG:any = {};
  editLib:any = false;
  
  constructor(private dataService: DataService) { }

  ngOnInit() {
    
    if(this.mode == 2){
      this.limit = 3;
    }

    this.loadGoals();
    this.loadDepartments();
  }

  loadGoals(assessment_id = this.selectedAssessment.id){
    this.dataService.getAssessmentGoalsActivities(this.id, assessment_id, this.limit, this.selectedDept.id).subscribe( (data:any = {goals:[]} )=> {
  		this.goals = data.goals;
      
      
    });
  }

  loadDepartments(){
    this.dataService.getDepts2().subscribe( (data:any )=> {
  		this.departments = data;
    });
  }

  selectGoal(goal){
    this.selectedGoal = goal;
  }
  selectSubgoal(sg){
    this.selectedSubgoal = sg;
    
  }
  selectTask(task){
    this.selectedTask = task;
  }

  loadActivities(goal_id) {
  	this.dataService.getGaActivities(goal_id, this.id).subscribe( (data: any[] )=> {
  		
      	this.activities = data;
      
    });
  }

  loadGoalEv(goal){
    this.dataService.getGoalEv(goal, this.id).subscribe( (data: any[] )=> {
      this.selectedEv = data;
      
    });   
  }

  setEvVal(val){
    this.evVal = val;
  }

  setPrompt(param, val){
    
    if(param == 'status'){
      if(val == 'on') {
        val = 1;
      } else {
        val = 0;
      }
    }

    this.evPrompt[param] = val;
    

  }

  markTrial(){
    let data = {
      patient_id:this.id,
      assignment_id:this.selectedGoal.id,
      task_id: this.selectedTask.id,
      score: this.evVal,
      evPrompt: this.evPrompt,
      notes:this.evNotes
    };

    
    this.dataService.postTaskEvaluation(data).subscribe( (res:any) => {
      if (res.status == 0) {
        this.selectedTask.progress = res.progress;
        this.selectedTask.trial_count = res.trial_count;
        this.evPrompt = {status:0, level:0};
        this.selectedTask = {id:0, task_title:""};
        this.evNotes = "";
      } else {
        alert(res.msg);
      }
    }); 

  } 

  closeEv(){
    this.selectedTask = {id:0, task_title:""};
  }

  closeGoalEv(){
    this.selectedGoal = {id:0};
  }

  closeSgEv(){
    this.selectedSubgoal = {id:0};
  }

  markGoalTrial(){
    let data = {
      patient_id:this.id,
      assignment_id:this.selectedGoal.id,
      score: this.evVal,
      evPrompt: this.evPrompt,
      notes:this.evNotes
    };

    
    this.dataService.postGoalEvaluation(data).subscribe( (res:any) => {
      if (res.status == 0) {
        this.selectedGoal.trials.progress = res.progress;
        this.selectedGoal.trials.count = res.trial_count;
        this.evPrompt = {status:0, level:0};
        this.selectedGoal = {id:0};
        this.evNotes = "";
      } else {
        alert(res.msg);
      }
    }); 

  }
  
  markSgTrial(){
    let data = {
      patient_id:this.id,
      assignment_id:this.selectedGoal.id,
      subgoal_id:this.selectedSubgoal.id,
      score: this.evVal,
      evPrompt: this.evPrompt,
      notes:this.evNotes
    };

    
    this.dataService.postSubgoalEvaluation(data).subscribe( (res:any) => {
      if (res.status == 0) {
        this.selectedSubgoal.progress = res.progress;
        this.selectedSubgoal.trial_count = res.trial_count;
        this.evPrompt = {status:0, level:0};
        this.selectedSubgoal = {id:0};
        this.evNotes = "";
      } else {
        alert(res.msg);
      }
    }); 

  }

  markGoalDone(goal){
    var cnf = confirm("Do you really want to mark this goal as completed?");

    if(cnf){

      let data = {
        patient_id:this.id,
        goal_assignment_id:goal.id
        
      }
  
      this.dataService.postMarkGoalCompletion(data).subscribe( (res:any) => {
        if (res.status == 0) {
          goal.is_completed = 1;
        } else {
          alert(res.msg);
        }
      }); 
    }
  }

  markSubgoalDone(sg){
    var cnf = confirm("Do you really want to mark this subgoal as completed?");

    if(cnf){

      let data = {
        patient_id:this.id,
        goal_assignment_id:this.selectedGoal.id,
        subgoal_id:sg.id
        
      }
  
      this.dataService.postMarkSubgoalCompletion(data).subscribe( (res:any) => {
        if (res.status == 0) {
          sg.is_completed = 1;
        } else {
          alert(res.msg);
        }
      }); 
    }
  }

  markTaskDone(task){

    var cnf = confirm("Do you really want to mark this activity as completed?");

    if(cnf){

      let data = {
        patient_id:this.id,
        goal_assignment_id:this.selectedGoal.id,
        task_id:task.id
      }
  
      this.dataService.postMarkTaskCompletion(data).subscribe( (res:any) => {
        if (res.status == 0) {
          task.is_completed = 1;
        } else {
          alert(res.msg);
        }
      }); 
    }

    

  }

  trialHistory(task){
    this.evalHistory = {status:0, activity:"", trialdata:[]};
    this.dataService.getTaskEv(task.id, this.selectedGoal.id, this.id).subscribe( (data: any )=> {
  		if(data.status == 0){
        this.evalHistory = data;
        $("#modalEvalHistory").modal("show");
      } else {
        alert(data.msg);
      }
    });
  }

  goalTrialHistory(goal){
    this.evalHistory = {status:0, activity:"", trialdata:[]};
    this.dataService.getGoalEv(goal.id, this.id).subscribe( (data: any )=> {
  		if(data.status == 0){
        this.evalHistory = data;
        $("#modalEvalHistory").modal("show");
      } else {
        alert(data.msg);
      }
    });
  }

  subgoalTrialHistory(sg){
    this.evalHistory = {status:0, activity:"", trialdata:[]};
    this.dataService.getSubgoalEv(this.selectedGoal.id, sg.id, this.id).subscribe( (data: any )=> {
  		if(data.status == 0){
        this.evalHistory = data;
        $("#modalEvalHistory").modal("show");
      } else {
        alert(data.msg);
      }
  });
  }

  selectDept(dept){
    this.selectedDept = dept;
    this.loadGoals();
  }

  deleteGoal(goal){
    var cnf = confirm("Do you really want to delete this goal?");
    if(cnf){
      let data = {
        id:goal.id
      }

      this.dataService.postDeleteAssignedGoal(data).subscribe( (res:any) => {
        if (res.status == 0) {
          this.loadGoals();
        } else {
          alert(res.msg);
        }
      }); 

    }

  }

  toggleSelect(goal){
    goal.selected = !goal.selected;
    this.findSelectedAll();
  }

  toggleSelectAll(){
    let val = true;
    if(this.selectedAll == 1 || this.selectedAll == 1){
      val = false;
      
    }

    this.goals.forEach(goal => {
      goal.selected = val;
    });

    this.findSelectedAll();
  }

  findSelectedAll(){
    let selectedCount = 0;
    this.goals.forEach(goal => {
      if(goal.selected){
        selectedCount++;
      }
    });

    if(selectedCount == this.goals.length){
      this.selectedAll = 1;
    } else if(selectedCount == 0){
      this.selectedAll = 0;
    } else {
      this.selectedAll = 2;
    }

  }

  deleteSelected(){
    let selected = [];
    this.goals.forEach(goal => {
      if(goal.selected){
        selected.push(goal.id);
      }
    });

    if(selected.length > 0){
      let cnf = confirm("Confirm this action. Once deleted, these assigned goals can't be retrieved");
      this.dataService.postDeleteMultipleAssignedGoals(selected).subscribe( (res:any) => {
        if (res.status == 0) {
          this.loadGoals();
          this.findSelectedAll();
          this.selectedAll = 0;
        } else {
          alert(res.msg);
        }
      }); 

    } else {
      alert("Select at least one goal");
    }
  }

  deleteSubgoal(goal, sg){
    var cnf = confirm("Do you really want to delete this subgoal?");
    if(cnf){
      let data = {
        patient_id:this.id,
        assignment_id:goal.id,
        subgoal_id:sg.id
      }

      this.dataService.postDeleteAssignedSubgoal(data).subscribe( (res:any) => {
        if (res.status == 0) {
          this.loadGoals();
        } else {
          alert(res.msg);
        }
      }); 

    }

  }

  submitSub(data) {
    $('#btn-add-subgoal').attr('disabled', 'true');
    data.goal_id = this.selectedGoal.goal_id;
    data.addToLib = this.addSgToLib;
    data.assignment_id = this.selectedGoal.id;

    

    this.dataService.postAddGaActivity(data).subscribe( (res:any) => {
      $('#btn-add-subgoal').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-subgoal').modal('hide');
            $('.btn-cancel-subgoal').click();
            //@ts-ignore
            /*this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                
                
            } ); */

            this.loadGoals();
            
            
          } else {
            alert(res.msg);
          }
    });

    

  }

  submitESub(data) {
    $('#btn-edit-subgoal').attr('disabled', 'true');
    data.id = this.selectedSG.id;
    data.editLib = this.editLib;
    data.assignment_id = this.selectedGoal.id;
    data.patient_id = this.id;
    

    this.dataService.postEditGaActivity(data).subscribe( (res:any) => {
      $('#btn-edit-subgoal').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-edit-subgoal').modal('hide');
            $('.btn-cancel-subgoal').click();
            //@ts-ignore
            this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                //this.loadGoals();
                
            } );
            
            
          } else {
            alert(res.msg);
          }
    });
    
    

  }

  toggleSGLib(){
    this.addSgToLib = !this.addSgToLib;
  }

  toggleTaskLib(){
    this.addTaskToLib = !this.addTaskToLib;
  }

  toggleEditLib(){
    this.editLib = !this.editLib;
  }

  selectSG(subgoal){
    this.selectedSG = subgoal;
  }
  

  submitTask(data) {
    //@ts-ignore
    data.activity_id = this.selectedSG.id;
    data.task_trials = 1;
    data.addToLib = this.addTaskToLib;
    data.assignment_id = this.selectedGoal.id;
    $('#btn-add-task').attr('disabled', 'true');

    console.log(data, this.selectedSG);

    this.dataService.postAddGaTask(data).subscribe( (res:any) => {
      $('#btn-add-task').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-task').modal('hide');
            $('.btn-cancel-task ').click();
            this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                this.loadGoals();
                
            } );
            
            
          } else {
            alert(res.msg);
          }
    }); 

    

  }


  submitETask(data) {
    //@ts-ignore
    data.id = this.selectedTask.id;
    $('#btn-edit-task').attr('disabled', 'true');

    console.log(data, this.selectedSG);

    this.dataService.postEditGaTask(data).subscribe( (res:any) => {
      $('#btn-edit-task').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-edit-task').modal('hide');
            $('.btn-cancel-task ').click();
            this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                //this.loadGoals();
                
            } );
            
            
          } else {
            alert(res.msg);
          }
    }); 

    

  }

  deleteTask(goal, task){
    var cnf = confirm("Do you really want to delete this activity?");
    if(cnf){
      let data = {
        patient_id:this.id,
        assignment_id:goal.id,
        task_id:task.id
      }

      this.dataService.postDeleteAssignedTask(data).subscribe( (res:any) => {
        if (res.status == 0) {
          this.loadGoals();
        } else {
          alert(res.msg);
        }
      }); 

    }

  }
  

}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss']
})
export class InfoHeaderComponent implements OnInit {

  @Input('id') id:any;
  client:any = {name:"", reg_no:"", address:"", phone:"", email:"", last_visit:"", age:"", dob:"", gender:"", class_std:""}
  constructor(private dataService: DataService, private router:Router) { }

  ngOnInit() {
    this.loadClient();
  }

  loadClient(){
    this.dataService.getClientProfile(this.id).subscribe( (data: any[] )=> {
      this.client = data;
      
    });
  }

}

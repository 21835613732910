import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./newdashboard.scss','./calandar.scss', './home.component.scss']
})
export class HomeComponent implements OnInit {

	selected: Date | null; //Non-ISO for calendar;
	date:Date | null; //Don't know why;
	data:any = {date:"", upcoming_appointments:[], consultations:[], recent_appointments:[], force_session_report:0};
	selectedDate = ''; //ISO string;
	incompleteRegistrations:any = [];
	reschedule:any = {};
	selectedGroupSession:any = {participants:[], start_time:"", end_time:""}
	selectedVisit:any = {report:{id:0, body:""}}
  	constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router) { }

	ngOnInit() {
		this.selectedDate = this.dataService.localData.homeDate; 

		if(this.selectedDate != "" && this.selectedDate != null && this.selectedDate.length > 5) {
			this.selected = new Date(this.selectedDate);
		}

		this.date = new Date()
		this.loadDashboard();
		this.loadIncomplete();

		$( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "yy-mm-dd",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});

	}

	timerOut(){
		var date1 = new Date();
		var date2 = new Date('2019-12-02 17:06:04');
		var diff = 0;
		//diff = date1 - date2;
		//return diff;
	}

	setToday(){
		this.selected=new Date();
		this.selectedDate = new Date().toISOString().split('T')[0];;
		this.loadDashboard();
	}

	loadDashboard(){
		this.dataService.getTherapistDashboard(this.selectedDate).subscribe( (data: any[] )=> {
			this.data = data;
			
		} )
	}

	loadIncomplete(){
		this.dataService.getIncompleteRegistrations().subscribe( (data: any[] )=> {
			this.incompleteRegistrations = data;
			
		} )
	}

	

	endSession(id) {
		var data = {"appointment_id":id};
		this.dataService.endSession(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	startSession(id) {
		
		var data  = {"appointment_id":id};
		this.dataService.startSession(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
				
			} else {
				alert(res.msg);
			}

		});
	}

	markComplete(visit) {
		

		if(!visit.has_completed){

			let proceed;
			if(this.data.force_session_report == 1){
				if(visit.report.id == 0){
					proceed = false;
					visit.close_on_sr = true;
					this.selectVisit(visit);
					$("#modal-new-sr").modal("show");
				} else {
					proceed = true;
				}
			} else {
				proceed = true;
			}

			if(proceed){
				visit.has_completed = true;
				var data  = {"appointment_id":visit.appointment_id};
				this.dataService.postMarkComplete(data).subscribe( (res: any) => {
					if(res.status == 0){
						this.loadDashboard();
						if(res.is_nabidh == 1){
							
							//redirect to session report if TBC
							if(res.reportCount == 0){
								this.router.navigate(["/dashboard/tbc/"+visit.patient_id+"/session-report"]);
							} else {
								//Auto-send T02
								this.dataService.sendNabidhFinalSessionReport(visit.appointment_id).subscribe( (data:any )=> {
									//console.log(data);
								} );
							}
							
							

							//Send nabidh request;
							this.dataService.sendNabidhA03(visit.appointment_id).subscribe( (data:any )=> {
								//console.log(data);
							} );
						}
					} else {
						alert(res.msg);
					}

				});
			}

		}
		
	}

	markGSComplete(visit) {
		if(!visit.has_completed){
			visit.has_completed = true;
			var data  = {"appointment_id":visit.appointment_id};
			this.dataService.postMarkGSComplete(data).subscribe( (res: any) => {
				if(res.status == 0){
					this.loadDashboard();
					
				} else {
					alert(res.msg);
				}

			});
		}
		
	}

	markConsultation(id) {
		var data  = {"id":id};
		this.dataService.postMarkConsultation(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	undo(id, type) {
		var data = {"id":id, "type":type};
		this.dataService.postUndoCompleted(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	markAbsent(id){
		var data  = {"id":id};
		this.dataService.postMarkAbsent(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	undoAbsent(id) {
		console.log(id);
		var data  = {"id":id};
		this.dataService.postUndoAbsent(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	switchDate(date) {
		this.selectedDate = date;
		this.dataService.localData.dateDashboard = date;
		this.loadDashboard();
	}
	

	moveDate(mode){
		var newDate;
		var selected = (this.selectedDate.length > 5) ?   new Date(this.selectedDate) : new Date() ;
		if(mode == 1){
			newDate = new Date(new Date(selected).setDate(selected.getDate() + 1));
		} else {
			newDate = new Date(new Date(selected).setDate(selected.getDate() - 1));
		}

		this.selectedDate = newDate.toISOString().split('T')[0];
		this.dataService.localData.dateDashboard = this.selectedDate;
		this.loadDashboard();
	}

	confirmAppointmentStatus(appointment, attendance){

		let data = {id:appointment.appointment_id, attendance: attendance}

		this.dataService.postMarkAttendance(data).subscribe( (res: any) => {
			if(res.status == 0){
				//this.loadDashboard();
				appointment.attendance = attendance;
			} else {
				alert(res.msg);
			}

		});
	}

	cancelAppointment(appointment){

		var cnf = confirm("Do you really want to cancel this appointment?");

		if(cnf){
			this.dataService.cancelAppointment(0, appointment.appointment_id).subscribe( (res: any) => {
				if(res.status == 0){
					this.loadDashboard();
					if(res.is_nabidh == 1){
						//Send nabidh request;
						this.dataService.sendNabidhA11(appointment.appointment_id).subscribe( (data:any )=> {
							//console.log(data);
						} );
					}
				} else {
					alert(res.msg);
				}
	
			});
		}
		
		

	}

	selectAppointmentReschedule(appointment){
		this.reschedule = appointment;

		//$('#rescheduleModal').modal('show');

	}

	startAppointment(appointment, mode=1){
		if(!appointment.has_started){
			appointment.has_started = true;
			this.dataService.startTherapySession(appointment.appointment_id, mode).subscribe( (data:any = {status:0, msg:""} )=> {
				if(data.status == 0){
					appointment.is_active = mode;
					if(mode == 1){
						if(data.is_nabidh == 1){
							//Send nabidh request;
							this.dataService.sendNabidhA04(appointment.appointment_id).subscribe( (data:any )=> {
								//console.log(data);
							} );
						}
						this.data.active_count++;
						this.data.upcoming_count--;
					} else {
						this.data.active_count--;
						this.data.upcoming_count++;
					}
				} else {
					alert(data.msg);
				}
			} );

		}
		
	}

	startGroupSession(appointment, mode=1){
		if(!appointment.has_started){
			appointment.has_started = true;
			this.dataService.startGroupSession(appointment.appointment_id, mode).subscribe( (data:any = {status:0, msg:""} )=> {
				if(data.status == 0){
					appointment.is_active = mode;
					if(mode == 1){
						this.data.active_count++;
						this.data.upcoming_count--;
					} else {
						this.data.active_count--;
						this.data.upcoming_count++;
					}
				} else {
					alert(data.msg);
				}
			} );

		}
		
	}

	ignoreIncomplete(p){
		let data = {id:p.id};
		this.dataService.postIgnoreIncompleteRegistration(data).subscribe( (res: any) => {
			
			if(res.status == 0){
				this.loadIncomplete();
			} else {
				alert(res.msg);
			} 

		});
	}


	onCalendarSelect(date){
		var offset = date.getTimezoneOffset() * 60000;
		var isoDate = new Date(date.getTime() - offset ).toISOString().split('T')[0];
		this.selectedDate = isoDate;
		this.dataService.localData.homeDate = isoDate;
		//this.dataService.localData.dateDashboard = isoDate;
		this.loadDashboard();
		console.log(this.selected);
	}

	loadEdPatient(patient){
		this.dataService.getPatientProfile(patient.id).subscribe( (data:any )=> {
			this.dataService.edPatient = data;
			
		} )
	}

	viewGS(slot){
		this.selectedGroupSession = slot;
		$('#modal-group-session').modal('show');
	}

	submitSessionReport(data){
		data.visit_id = this.selectedVisit.appointment_id;
		data.patient_id = this.selectedVisit.patient_id;
		

		this.dataService.postNewSR(data).subscribe( (res: any) => {
			if(res.status == 0){
				//this.loadDashboard();
				this.selectedVisit.report = res.report;
				$("#modal-new-sr").modal("hide");
				$(".btn-close-modal").trigger("click");

				if(this.selectedVisit.close_on_sr){
					this.markComplete(this.selectedVisit);
				}
				
			} else {
				alert(res.msg);
			}

		});

	}

	selectVisit(visit){
		this.selectedVisit = visit;
		console.log(this.selectedVisit);
	}

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-audio-test',
  templateUrl: './audio-test.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss','./audio-test.component.scss']
})
export class AudioTestComponent implements OnInit {

  id = '0';
  testId:any = 0;
  data:any = {};
  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.testId = this.route.snapshot.paramMap.get('testid');

    if(this.testId != 0){
      this.loadTest();
    }
  }

  loadTest(){
    
    this.dataService.getClientAudioTest(this.testId ).subscribe( (data:any = {status:0, data:[]} )=> {
      if(data.status == 0){
        this.data = data.data;
        console.log(this.data);
      }
    });
  }

  plotAudio(item){
    
    //Right

    var rightac = item.aright;
    var rightbc = item.bright;
    var leftac = item.aleft;
    var leftbc = item.bleft;
    console.log(rightac);

    //@ts-ignore
    $('#gright').highcharts({
        title: {
            text: 'Right',
            x: -20 //center
        },
        
        xAxis: {
            categories: ['125','250', '500', '1k', '2k', '4k', '8k']
        },
        yAxis: {
            reversed: true,
             showFirstLabel: false,
            title: {
                text: 'dB',
                reversed: true,
            },
            plotLines: [{
                value: 0,
                width: 1,
                color: '#FF0000'
            }]
        },
        tooltip: {
            
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            borderWidth: 0
        },
        series: [{
            name: 'Right AC',
            color: '#c80202',
            marker: {
                symbol: 'url(../../../../assets/img/circle.png)'
            },
            data: [Number(rightac.acr1), Number(rightac.acr2), Number(rightac.acr3), Number(rightac.acr4), Number(rightac.acr5), Number(rightac.acr6), Number(rightac.acr7)]
        },{
            name: 'Right BC',
            color: '#c80202',
            marker: {
                symbol: 'url(../../../../assets/img/cright.png)'
            },
            data: [Number(rightbc.bcr1),Number(rightbc.bcr2), Number(rightbc.bcr3), Number(rightbc.bcr4), Number(rightbc.bcr5), Number(rightbc.bcr6)]
        }]
    });

    //Left
    //@ts-ignore
    $('#gleft').highcharts({
        title: {
            text: 'Left',
            x: -20 //center
        },
        
        xAxis: {
            categories: ['125','250', '500', '1k', '2k', '4k', '8k']
        },
        yAxis: {
            reversed: true,
             showFirstLabel: false,
            title: {
                text: 'dB',
                reversed: true,
            },
            plotLines: [{
                value: 0,
                width: 1,
                color: '#FF0000'
            }]
        },
        tooltip: {
            
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            borderWidth: 0
        },
        series: [{
            name: 'Left AC',
            color: '#000f7e',
            marker: {
                symbol: 'url(../../../../assets/img/gcross.png)'
            },
            data: [Number(leftac.acl1),Number(leftac.acl2), Number(leftac.acl3), Number(leftac.acl4), Number(leftac.acl5), Number(leftac.acl6), Number(leftac.acl7)]
        },{
            name: 'Left BC',
            color: '#000f7e',
            marker: {
                symbol: 'url(../../../../assets/img/cleft.png)'
            },
            data: [Number(leftbc.bcl1),Number(leftbc.bcl2), Number(leftbc.bcl3), Number(leftbc.bcl4), Number(leftbc.bcl5), Number(leftbc.bcl6)]
            
        }]
    });

    //Open modal

    $('#modal-audio-graph').modal('show');

  }

}

import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';


@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  constructor() { }

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(e){
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = true;
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) onDragLeave(e){
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
  }

  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(e){
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
    let files = e.dataTransfer.files;
    if(files.length > 0){
      this.fileDropped.emit(files);
    }
  }



}

import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';


@Component({
  selector: 'app-leftchart',
  templateUrl: './leftchart.component.html',
  styleUrls: ['../patient-profile.component.scss' , './leftchart.component.scss']
})
export class LeftchartComponent implements OnInit {

  @Input('id') id:any;
  appointments:any = {status:0, appointments:[]};
  appMode:any = 1;
  appLimit:any = 5;

  //chart-demo

  colors = [
		'#F2B705',
		
		'#038C33',
		'#F20505',
		'#194A2F',
		'#8C1B39',
		'#FF6175',
		'#7AFF96',
		'#2A6637',

	];

  graphData:any = {
          "status":0,
          "graphdata":[25, 40, 50, 68],"dates":["1 Aug", "9 Aug", "10 Aug", "11 Aug"]
        };

  orgForms:any = [];
  
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadAppointments();
    this.plotGraph();
	this.loadOrgForms();
  }

  loadAppointments(){
    this.dataService.getClientAppointments(this.id, this.appMode, this.appLimit).subscribe( (data: any[] )=> {
      this.appointments = data;
      
    });
  }  

  loadOrgForms(){
	  this.dataService.getHtV2OrgForms().subscribe( (data: any ) => {
		  this.orgForms = data.forms;
		  
	  });
  }

  switchAppMode(mode){
    if(mode != this.appMode){
      this.appMode = mode;
      this.loadAppointments();
    }
  }

  plotGraph(){
    //@ts-ignore
		$('#graph').highcharts({
          chart:{
            height:150
          },
	        title: {
	            text: ' ',
	            x: -20 //center
	        },
	        
	        xAxis: {
	            gridLineWidth: 0,
	            //categories: [0,30,60,90, 120, 150, 180]
	            categories: this.graphData.dates
	        },
	        yAxis: {
	            reversed: false,
	            showFirstLabel: false,
	            //categories: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
	            min:0, 
	            max:100,
	            tickInterval: 25,
	            title: {
	                text: '',
	                reversed: false,
	            },
	            plotLines: [{
	                value: 0,
	                width: 0,
	                color: '#FF0000'
	            }]
	        },
	        tooltip: {
	            
	        },
	        legend: {
	            layout: 'vertical',
	            align: 'right',
	            verticalAlign: 'middle',
	            borderWidth: 0
	        },
	        series: [{
	            name: ' ',
	            color: '#802a8f',
	            type: 'spline',
	            /*marker: {
	                //symbol: 'url(img/graph-marker.jpg)'
	            },*/
	            data:this.graphData.graphdata,
	        }]
	    });
		//
	}

}

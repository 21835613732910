import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-assesment-tool',
  templateUrl: './assesment-tool.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss','./assesment-tool.component.scss']
})
export class AssesmentToolComponent implements OnInit {

  id:any = '0';
  limit:any = 30;
  data:any = [];
  formsLib:any = [];
  submissions:any = [];


  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData();
    this.loadSelected();
    this.loadSubmissions();
  }

  loadData(){
    this.dataService.getClientAssessments(this.id, this.limit).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

  loadLibrary(){
    this.dataService.getFormsLibrary(this.id).subscribe( (data: any )=> {
      this.formsLib = data.forms;
      
    });
  }

  loadSubmissions(){
    this.dataService.getPatientFormSubmissions(this.id).subscribe( (data: any )=> {
      this.submissions = data.data;
    });
  }

  loadSelected(){
    this.dataService.getFormsSelected(this.id).subscribe( (data: any )=> {
      this.formsLib = data.forms;
      
    });
  }

  reAssess(form_id){ //Re-assess a screening form
    this.dataService.getClientReAssessment(this.id, form_id).subscribe( (data:any = {status:0, submission_id:0} )=> {
      
      this.router.navigate(['/dashboard/client-assessment/'+this.id+'/'+form_id]);
      
    });
  }

  openTool(tool){ //Opening a screening form
    if(tool.last_submitted_at != null){
      //review
      this.router.navigate(['/dashboard/client-assessment-review/'+this.id+'/'+tool.form_id]);
    } else {
      //new form
      this.router.navigate(['/dashboard/client-assessment/'+this.id+'/'+tool.form_id]);
    }
  }

  openAssessmentTool(form){ //Opening an assessment tool/form
    this.router.navigate([form.route]);

    
  }

  reviewForm(sub){ //reviewing/opening a previous submission of an assessment tool/form
    console.log(sub);
    let route = "";
    switch (sub.type) {
      case "builder":
        route = "/dashboard/forms/"+this.id+"/view/"+sub.form_id+"/"+sub.submission_id;
        break;
      case "db":
        route = "/dashboard/preset-form/"+this.id+"/form/"+sub.form_id+"/"+sub.submission_id;
        break;
      case "preset":
        route = "/dashboard/preset-form/"+this.id+"/"+sub.slug; //+"/"+sub.submission_id;
        if(!sub.single_submission){
          route += "/"+sub.submission_id;
        }
        break;
      case "screening":
        route = "/dashboard/client-assessment-review/"+this.id+"/"+sub.form_id+"/"+sub.submission_id
        break;
    
      default:
        route = "";
        break;
    }

    if(route != ""){
      this.router.navigate([route]);
      
    } else {
      alert("No route defined");
    }

  }

  reAssessTool(sub){ //re-written to accomodate all type of forms. prev. version can only be used for screening forms
    let route = "";
    switch (sub.type) {
      case "builder":
        route = "/dashboard/forms/"+this.id+"/view/"+sub.form_id;
        break;
      case "db":
        route = "/dashboard/preset-form/"+this.id+"/form/"+sub.form_id;
        break;
      case "preset":
        route = "/dashboard/preset-form/"+this.id+"/"+sub.slug;
        break;
      case "screening":
        route = "/dashboard/client-assessment/"+this.id+"/"+sub.form_id
        break;
    
      default:
        route = "";
        break;
    }

    if(route != ""){
      this.router.navigate([route]);
    } else {
      alert("No route defined");
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: [ './tickets.component.scss' ]
})
export class TicketsComponent implements OnInit {

  constructor(private dataService: DataService) { }
  tickets:any = {results:[], total:0};
  ticket:any = {
    id:0,
    description:"",
    custom_filed: {
      cf_user_name:"",
      cf_user_id:"",
      cf_org_id:"",
      cf_issue_sumary:""
    },
    conversations:[]
  };

  ticketEmpty:any = {
    id:0,
    description:"",
    custom_filed: {
      cf_user_name:"",
      cf_user_id:"",
      cf_org_id:"",
      cf_issue_sumary:""
    },
    conversations:[]
  };

  loading:any = false;
  posting:any = false;
  loadingConvos:any = false;
  replyMessage:any = "";
  loadingTickets:any = false;
  ticketStatus:any = {
    "2":"Open",
    "3":"Pending",
    "4":"Resolved",
    "5":"Closed",
    "6":"Waiting on customer",
    "7":"Waiting on third-party"
  };

  ngOnInit() {
    this.loadTickets();
  }

  loadTickets(){
    this.loadingTickets = true;
    this.dataService.getFreshDeskTickets(this.dataService.localData.userId, this.dataService.localData.orgId).subscribe( (data: any )=> {
      this.tickets = data;
      this.loadingTickets = false;
    });
  }

  loadSingleTicket(id){
    this.loading = true;
    this.ticket = this.ticketEmpty;
    this.dataService.getFreshDeskTicket(id).subscribe( (data: any )=> {
      this.loading = false;
      this.ticket = data;
      
    });
  }

  ticketReply(){
    let data = {
      body:this.replyMessage,
      user_id:this.ticket.requester_id
    }
    this.posting = true;
    this.dataService.postTicketReply(data, this.ticket.id).subscribe( (res:any) => {
      this.replyMessage = "";
      this.posting = false;
      this.loadConversations();
    });

  }

  loadConversations(){
    this.loadingConvos = true;
    this.dataService.getFreshDeskTicketConversations(this.ticket.id).subscribe( (data: any )=> {
      this.ticket.conversations = data;
      this.loadingConvos = false;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-ma-form',
  templateUrl: './ma-form.component.html',
  styleUrls: ['../patient-profile.component.scss', './ma-form.component.scss']
})
export class MaFormComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }

  id = '0';
  formid = '0';
  formData:any = {status:0, form_name:"", questions:[]};
  limit = 10;
  showAfter = 0;
  patient:any = {phone:"", patient_name:""};
  loading = false;
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.formid = this.route.snapshot.paramMap.get('formid');
    this.loadPatient();

  }

  loadPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data: any ) => {
      this.patient = data;
		  this.loadForm(data.phone, data.patient_name);
		});
  }

  loadForm(phone, name){
    this.dataService.getHtV2OrgFormSingle(this.formid, phone, name, this.limit, this.showAfter).subscribe( (data: any ) => {
		  this.formData = data;
      console.log("f", this.formData);
		});
  }

  loadMore(){
    this.loading = true;
    this.showAfter = this.formData.questions[this.formData.questions.length - 1].question_id;
    this.dataService.getHtV2OrgFormSingle(this.formid, this.patient.phone, this.patient.patient_name, this.limit, this.showAfter).subscribe( (data: any ) => {
		  this.loading = false;
      data.questions.forEach(element => {
        this.formData.questions.push(element);  
      });
		});  
     
  }

}

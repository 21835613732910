import { Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SocketioService } from '../../socketio.service';
import * as OT from '@opentok/client';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss']
})
export class ChatWindowComponent implements OnInit, OnDestroy {

  data:any;
  id = '0';
  sessionId = "";
  sender:any;
  recipient:any;
  socket;
  
  /* video chat */
  isHometherapy = 0;
  chatRoomId = 0;

  session:OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  callStatus = 0;

  container: HTMLElement; 
  caller = [];
  displayCallingText = 1;
  displayCallAgain = 0;

  goalsLimit = 5;
  goals = [];
  selectedGoal = {};
  activities = [];

  levels = [];
  categories = [];
  selectedLevel = 0;
  selectedCategory = 0;
  libraryGoals = [];
  htOverview = {status: 0, activities: []};

  fileUpload = {status: '', message: '', filePath: ''};
  uploadForm: FormGroup;
  upFiles = [];

  	constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private socketService: SocketioService, private ref: ChangeDetectorRef, private formBuilder: FormBuilder) { 
  		this.changeDetectorRef = ref;
	}

  ngOnInit() {
  	this.socketService.setupSocketConnection();
  	this.id = this.route.snapshot.paramMap.get('id');
    this.sessionId = this.route.snapshot.paramMap.get('sessionId');
    console.log('sessionId', this.sessionId);
  	this.loadPatient();

  	this.loadGoalLevels();
    this.loadGoalCategories();
    this.loadLibraryGoals();

  	this.socketService.onRejectCall().subscribe( data => {
       console.log('call rejected');
       this.session.disconnect();
       this.displayCallAgain = 1;
       this.closeCamera();
    });

    this.uploadForm = this.formBuilder.group({
      doc: [''],
      file_title: "",
    });

  }

  ngOnDestroy() {
    
    if (this.chatRoomId != 0 ) {
      this.socketService.leaveRoom(this.chatRoomId);
    }

    this.rejectCall();
  }

  //



  getHometherapy(phone){
    this.dataService.getHometherapy(phone).subscribe( (data: any[] )=> {
      
      //@ts-ignore
      if (data.status == 0) {
        this.isHometherapy = 1;
      }
      
    } );
  }
  
  loadPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data: any[] )=> {
      this.data = data;
      
      //@ts-ignore
      this.getHometherapy(data.phone);
      this.startChat();
      this.loadHtOverview();
      this.loadGoals(this.data.phone);
    } );
  }



  startChat(){
  	
    this.dataService.getChatRoom(this.data.phone).subscribe( (data: any[] )=> {
      
      //@ts-ignore
      this.chatRoomId = parseInt(data.room_id);
      this.socketService.joinRoom(this.chatRoomId);
      
    } );

    this.getSender();
    this.getRecipient(this.data.phone);
    //this.videochatConnect();

  }

  getSender(){

    this.dataService.getSender().subscribe( (data: any[] )=> {
      
      this.sender =  data;

      if (this.sessionId == null) {
        this.videochatConnect();
      } else {
        this.acceptIncomingCall();
      }

      
      
    });

  }

  getRecipient(phone){
    
    this.dataService.getRecipient(phone).subscribe( (data: any[] )=> {
      this.recipient =  data;
      
      
    });
  }

  loadGoals(phone){
  	this.dataService.getHtPatientGoals(phone, this.goalsLimit).subscribe( (data: any[] )=> {
  		//@ts-ignore
      this.goals = data.goals;
      console.log('patient goals', data);
      
    });
  }



  videochatConnect(){
  	
    this.socketService.getOpenTokSession(this.sender.id).subscribe( data => {
      //this.sendVideocall(data.sessionId);
      console.log('sessionData', data);
      this.socketService.initSession(data.sessionId, data.sessionToken).then((session: OT.Session) => {
        this.session = session;
        this.sendVideocall(data.sessionId);
        this.session.on('streamCreated', (event) => {
          console.log('streamCreated', event);
          this.displayCallingText = 0;
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          console.log('stream destroyed', event);
          this.closeCamera();
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.changeDetectorRef.detectChanges();
          }
        });
      })
      .then(() => this.socketService.connectTok())
      .catch((err) => {
        this.closeCamera();
        //console.log('unable to connect', err);
        alert('Unable to connect.');
        
      });


    });
   
  
  }

  acceptIncomingCall(){
    this.displayCallingText = 0;
    this.socketService.joinOpenTokSession(this.sender.id, this.sessionId).subscribe( sessionToken => {

        
      //@ts-ignore
      this.socketService.receiveSession(this.sessionId, sessionToken).then((session: OT.Session) => {
        
        this.callStatus = 1;
        this.session = session;
        this.session.on('streamCreated', (event) => {
          this.streams.push(event.stream);
          //this.changeDetectorRef.detectChanges();
          this.ref.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          console.log('Stream destroyed');
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.ref.detectChanges();
            
          }
        });

        this.session.on("sessionDisconnected", function(event) {
           console.log('Session disconnected');
           
       });

      })
      .then(() => this.socketService.connectTok())
      .catch((err) => {
        console.error(err);
        alert('Unable to connect. ');
      });
     
    });
  }

  initiateChat(){
    this.callStatus = 1;
    this.displayCallingText = 1;
    this.displayCallAgain = 0;
    this.videochatConnect();
    

  }

  sendVideocall(sessionId){
    
    var data = {
      roomId: this.chatRoomId,
      sessionId: sessionId,
      onesignalId: this.recipient.onesignal_id,
      //onesignalId: 'c8284b29-98d5-4d3c-baf2-dbf5e011f634',
      callType: 'video',
      callFromId: this.sender.id,
      callFromName: this.sender.name,
      callToId: this.recipient.id,
      callToName: this.recipient.name,
      token: localStorage.getItem('key'),
    };

    console.log('callData', data);

    this.socketService.startVideoCall(data);
  }

  rejectCall(){

    //send callend push

    var data = {
      content_available: true,
      room_id: this.chatRoomId,
      onesignalId:this.recipient.onesignal_id,
      message: {
        title: "End Call",
        content: "End Call",
        data: {
          type: "callend",
          roomId:this.chatRoomId,
          fromId: this.sender.id,
          fromName: this.sender.name,
          toId:this.recipient.id

        }
      }

    };
    console.log('callend_data', JSON.stringify(data) );
    this.dataService.postCallEnd(data).subscribe( (res:any) => {
      //console.log('callend', res);

    });

    this.session.disconnect();
    //this.session.unsubscribe();
    this.socketService.rejectCall(this.chatRoomId);
    //this.callStatus = 0;
    $('#modal-receive-call').modal('hide');
    this.displayCallAgain = 1;
    this.displayCallingText = 0;
    this.closeCamera();

    


     
  }

  closeCallWindow(){
    this.rejectCall();
    this.callStatus = 0;
    window.close();

  }

  submitSym(data) {
    data.patient_id = this.id;

    this.dataService.postHtSymptoms(data).subscribe( (res:any) => {
      alert(res.msg);

      if (res.status == 0) {
          //@ts-ignore
         document.getElementById("formSym").reset(); 
         this.loadHtOverview();
      }


    });

  }

  resetFormSym(){
    //@ts-ignore
    document.getElementById("formSym").reset(); 
  }

  loadHtOverview(){

    this.dataService.getHtPatientOverview(this.id).subscribe( (data: any[] )=> {
      //@ts-ignore
      this.htOverview = data;
      
      
    } );

  }

  closeCamera(){
    console.log('Trying to stop camera');
    navigator.mediaDevices.getUserMedia({video: true, audio: false})
      .then(mediaStream => {
        const stream = mediaStream;
        console.log('stream', stream);
        stream.getTracks().forEach(function(track) {
          console.log('track', track);
          track.stop();
        });

      });

  }
  

  selectGoal(goal) {
  	this.selectedGoal = goal;
  	this.loadActivities(goal.goal_id);
  }

  loadActivities(goal_id) {
  	this.dataService.getHtPatientActivities(goal_id).subscribe( (data: any[] )=> {
  		
      	this.activities = data;
      
    });
  }


  loadGoalLevels(){
    this.dataService.getHtGoalLevels().subscribe( (data: any[] )=> {
      this.levels = data;

    });  
  }

  loadGoalCategories(){
    this.dataService.getHtGoalCategories().subscribe( (data: any[] )=> {
      this.categories = data;
      console.log('categories', data);
    });  
  }

  loadLibraryGoals(){
    this.dataService.getHtLibraryGoals(this.selectedLevel, this.selectedCategory).subscribe( (data: any[] )=> {
        this.libraryGoals = data;
        console.log('goals', data);
    });   
  }

  changeLevel(value) {
    this.selectedLevel = value;
    this.loadLibraryGoals();
  }

  changeCategory(value) {
    this.selectedCategory = value;
    this.loadLibraryGoals();
  }

  selectLibGoal(index) {
    //console.log('event', event.value);
    //@ts-ignore
    let status = this.libraryGoals.goals[index].select_status;
    
    if (status == 0) {
      //@ts-ignore
      this.libraryGoals.goals[index].select_status = 1;
    } else {
      //@ts-ignore
      this.libraryGoals.goals[index].select_status = 0;
    }
  }

  submitAssignGoals(data){
    let selectedGoals = [];
    //@ts-ignore    
    this.libraryGoals.goals.forEach(function(k, i){
      if (k.select_status == 1) {
        selectedGoals.push(k);
      }
    });
    $('#btn-assign-goals').attr('disabled', 'true');
    $('#btn-assign-goals').text('Assigning');
    data.selected_goals = selectedGoals;
    //@ts-ignore
    data.phone = this.data.phone;
    console.log(data);

    this.dataService.postHtAssignGoals(data).subscribe( (res:any) => {
      $('#btn-assign-goals').removeAttr('disabled');
      $('#btn-assign-goals').text('Assign');
      if (res.status == 0) {
        $('#modal-assign-goal').modal('hide');
        //@ts-ignore
        this.loadGoals(this.data.phone);
        //send push
        
        this.dataService.postHtSendPush(res.goal_ids).subscribe( (res:any) => {
          //do nothing
        });
        


      } else {
        alert(res.msg);
      }


    });
  }

  markGoal(goal) {
    var data = {goal_id:goal};
    this.dataService.postHtMarkGoal(data).subscribe( (res:any) => {
      if (res.status == 0) {
        //@ts-ignore
        this.loadGoals(this.data.phone);  

        this.dataService.postHtSendPushGoalComplete(res.goal_id).subscribe( (res:any) => {
          //do nothing
        });
      } else {
        alert(res.msg);
      }


    });
  }

  openFileWindow(){
    $('#patientFileInput').click();
  }

  onFileSelect(event) {
    $('#btnUpload').addClass('disabled');
    $('#btnUpload').text('Uploading');
    
    if(event.target.files.length > 0) {
      
      
      this.uploadForm.get('doc').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }
      
      const formData = new FormData();

      if(this.upFiles.length){
         for(let i=0 ; i < this.upFiles.length ; i++)
           formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
      }
      formData.append('patient_phone', this.data.phone);

      this.dataService.postChatFile(formData).subscribe( (res:any) => {
        this.upFiles = [];
        $('#btnUpload').removeClass('disabled');
        $('#btnUpload').text('Browse');
        
        if(res.status == 0) {

          //Send push to user and emit to socket

          var msg= {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            image: res.file_url,
            roomId: this.chatRoomId,
            user: {
              _id: this.sender.id,
              name: this.sender.name,
              avatar: "https://met-public.s3.ap-south-1.amazonaws.com/icon-user.png"
            }
          };

          var data = {
            from: {
                name: this.sender.name,
                userId: this.sender.id,
                  },
            to: {
                name: this.recipient.name,
                userId: this.recipient.id,
                onesignalId: this.recipient.onesignal_id,
                },
            roomId: this.chatRoomId,
            message: msg,
            token: localStorage.getItem('key'),
            pushContent: 'New image received',
        };

        this.socketService.sendMessage(data);


        alert('Upload successful');
      }
       


      });
      
      

    }
    

  }


}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client-wallet',
  templateUrl: './client-wallet.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './client-wallet.component.scss']
})
export class ClientWalletComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  id:any = 0;
  data:any = {balance:0, transactions:[], count:0}
  limit:any = 30;
  offset:any = 0;
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData();
  }

  loadData(){
    this.dataService.getClientWallet(this.id, this.limit, this.offset).subscribe( (data: any[] )=> {
      this.data = data;
      
    });
  }

  addMoney(data){
    
    data.patient_id = this.id;
    
    this.dataService.postClientWalletAdd(data).subscribe( (res:any) => {
      if(res.status == 0){
        this.loadData();
        $(".btn-close-modal").trigger("click");
      } else {
        alert(res.msg);
      }
    });
    

    

  }

  refundMoney(){
    let amount = prompt("Enter amount", this.data.balance);

    if(amount){
      let data = {
        patient_id:this.id,
        amount:amount
      }
  
      this.dataService.postClientWalletRefund(data).subscribe( (res:any) => {
        if(res.status == 0){
          this.loadData();
        } else {
          alert(res.msg);
        }
      });
    }
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
      if(this.offset < 0){
        this.offset = 0;
      }
    }

    this.loadData();

  }

}

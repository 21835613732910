import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute,private dataService: DataService, private location: Location) {};
  images = ['../../assets/img/loader.jpg'];
  id = '0';
  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');

    this.dataService.getDocumentPages(this.id).subscribe( (data: any[] )=> {
      console.log(data);
      //@ts-ignore
      if (data.status == 0) {
        //@ts-ignore
        this.images = data.files;
      }
      
      
    } );
  }

  goBack(){
    this.location.back();
  }

}

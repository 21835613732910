import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { SocketioService } from '../../socketio.service';

const publish = () => {

};

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss']
})

export class PublisherComponent implements AfterViewInit {
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @Input() session: OT.Session;
  publisher: OT.Publisher;
  publishing: Boolean;

  constructor(private socketService: SocketioService) {
    this.publishing = false;
  }

  ngAfterViewInit() {
    const OT = this.socketService.getOT();
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {insertMode: 'append', resolution: '320x240'});

    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
      $('.OT_publisher').css('z-index', '9999999');
      $('.OT_publisher').css('width', '250px');
      $('.OT_publisher').css('height', '300px');
      $('.OT_publisher').css('position', 'relative');
      $('.OT_publisher').css('left', '0');
      $('.OT_publisher').css('top', '5%');
      $('.OT_publisher').css('border-radius', '3px');
    }
  }

  publish() {
    this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
      } else {
        this.publishing = true;
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-receptionist-home',
  templateUrl: './receptionist-home.component.html',
  styleUrls: ['./receptionist-home.component.scss']
})
export class ReceptionistHomeComponent implements OnInit {

  constructor(private dataService: DataService) { }
  data:any = {upcoming_appointments:[], today:""};
  recent:any = {recent_patients:[]};
  limit = 10;
  recentLimit = 10;
  departments = [];
  dept = 0;
  branch:any = {id:0, name:""};
  selectedDate:any = "";

  /* admin use */
  branches = [];
  selectedBranch:any = 0;
  selectedGroupSession:any = {participants:[], start_time:"", end_time:""}

  ngOnInit() {

    if(localStorage.getItem("branch") !== null){
      this.branch = JSON.parse( localStorage.getItem("branch"));
      
    }

    this.selectedDate = this.dataService.localData.dateDashboardReception;

  	this.loadReception();
    this.loadRecent();
    this.loadBranches();

    this.dataService.getDepts().subscribe( (data: any[] ) =>{
      this.departments = data;
    });

    $( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "yy-mm-dd",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});


  }

  loadReception(){

    //@ts-ignore
    let branch = (this.dataService.localData.roleId == 1 || this.dataService.localData.roleId == '1') ? this.selectedBranch : this.branch.id;
    
  	this.dataService.getReception(this.limit, this.dept, branch, this.selectedDate).subscribe( (data: any[] )=> {
  		this.data = data;

  	});

  }

  loadRecent(){

    //@ts-ignore
    let branch = (this.dataService.localData.roleId == 1 || this.dataService.localData.roleId == '1') ? this.selectedBranch : this.branch.id;

    this.dataService.getReceptionRecent(this.recentLimit, this.selectedDate, branch).subscribe( (data: any[] )=> {
      this.recent = data;
      

    });
  }

  loadBranches(){
  	this.dataService.getBranches().subscribe( (data: any[] )=> {
  		this.branches = data;
  		
  	});
  }  

  markPresent(id) {
    
    var data = {"appointment_id":id};
    this.dataService.postMarkPresent(data).subscribe( (res:any) => {
        if(res.status == 0) {
            
            this.loadReception();
            
            
          } else {
            alert(res.msg);
          }
      } );

  }

  markAbsent(id) {
    
    var data = {"id":id};
    this.dataService.postMarkAbsent(data).subscribe( (res:any) => {
        if(res.status == 0) {
            
            this.loadReception();
            
            
          } else {
            alert(res.msg);
          }
      } );

  }

  loadMore() {
    this.limit +=10;
    this.loadReception();
  }

  loadMoreRecent() {
    this.recentLimit +=10;
    this.loadRecent();
  }

  filterDepartment(dept){
    this.dept = dept;
    this.loadReception();
  }

  moveDate(mode){
		var newDate;
    
		var selected = (this.selectedDate.length > 5) ?   new Date(this.selectedDate) : new Date() ;
		if(mode == 1){
			newDate = new Date(new Date(selected).setDate(selected.getDate() + 1));
		} else {
			newDate = new Date(new Date(selected).setDate(selected.getDate() - 1));
		}
    
		this.selectedDate = newDate.toISOString().split('T')[0];
    this.dataService.localData.dateDashboardReception = this.selectedDate;
		this.loadReception();
    this.loadRecent();
	}

  switchDate(date) {
		this.selectedDate = date;
    this.dataService.localData.dateDashboardReception = date;
		this.loadReception();
    this.loadRecent();
	}

  switchBranch(branch) {
    this.selectedBranch = branch;
    this.loadReception();
    this.loadRecent();
  }

  cancelAppointment(appointment){

		var cnf = confirm("Do you really want to cancel this appointment?");

		if(cnf){
			this.dataService.cancelAppointment(0, appointment.appointment_id).subscribe( (res: any) => {
				if(res.status == 0){
					this.loadReception();
				} else {
					alert(res.msg);
				}
	
			});
		}
		
		

	}

  viewGS(slot){
    this.selectedGroupSession = slot;
    $('#modal-group-session').modal('show');
  }


}

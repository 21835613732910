import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss','./progress-tracker.component.scss']
})
export class ProgressTrackerComponent implements OnInit {

  id:any = '0';

  colors = [
		'#F2B705',
		
		'#038C33',
		'#F20505',
		'#194A2F',
		'#8C1B39',
		'#FF6175',
		'#7AFF96',
		'#2A6637',

	];

  graphData:any = {
          "status":0,
          "graphdata":[25, 40, 50, 68, 63, 69, 71, 74, 80],
          "dates":["1 Aug", "9 Aug", "10 Aug", "11 Aug", "12 Aug", "14 Aug", "17 Aug", "19 Aug", "21 Aug"]
        };

	developmentalSeries:any = [{
			name: 'Actual Age',
			color: '#802a8f',
			type: 'spline',
			/*marker: {
				//symbol: 'url(img/graph-marker.jpg)'
			},*/
			data:[10, 20, 30, 40, 50]
		},
		{
			name: 'Social',
			color: '#038C33',
			type: 'spline',
			/*marker: {
				//symbol: 'url(img/graph-marker.jpg)'
			},*/
			data:[16, 52, 2, 10, 1]
		},
		{
		name: 'Language',
		color: '#F20505',
		type: 'spline',
		/*marker: {
			//symbol: 'url(img/graph-marker.jpg)'
		},*/
		data:[55,68,75, 92, 31]
		},
		{
		name: 'Cognitive',
		color: '#194A2F',
		type: 'spline',
		/*marker: {
			//symbol: 'url(img/graph-marker.jpg)'
		},*/
		data:[63, 45, 76, 82, 47]
		},
		{
		name: 'Movement',
		color: '#ffc10e',
		type: 'spline',
		/*marker: {
			//symbol: 'url(img/graph-marker.jpg)'
		},*/
		data:[69, 1, 64, 84, 59]
		},
	];

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.plotGraph();
    this.plotDevelopmentalGraph();
  }

  plotGraph(){
    //@ts-ignore
		$('#graphProgress').highcharts({
          /*chart:{
            height:150
          },*/
	        title: {
	            text: ' ',
	            x: -20 //center
	        },
	        
	        xAxis: {
	            gridLineWidth: 0,
	            //categories: [0,30,60,90, 120, 150, 180]
	            categories: this.graphData.dates
	        },
	        yAxis: {
	            reversed: false,
	            showFirstLabel: false,
	            //categories: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
	            min:0, 
	            max:100,
	            tickInterval: 25,
	            title: {
	                text: '',
	                reversed: false,
	            },
	            plotLines: [{
	                value: 0,
	                width: 0,
	                color: '#FF0000'
	            }]
	        },
	        tooltip: {
	            
	        },
	        legend: {
	            layout: 'vertical',
	            align: 'right',
	            verticalAlign: 'middle',
	            borderWidth: 0
	        },
	        series: [{
	            name: 'Therapy Progress',
	            color: '#802a8f',
	            type: 'spline',
	            /*marker: {
	                //symbol: 'url(img/graph-marker.jpg)'
	            },*/
	            data:this.graphData.graphdata,
	        }]
	    });
		//
	}

  plotDevelopmentalGraph(series=this.developmentalSeries){
    //@ts-ignore
		$('#graphDev').highcharts({
          /*chart:{
            height:150
          },*/
	        title: {
	            text: ' ',
	            x: -20 //center
	        },
	        
	        xAxis: {
	            gridLineWidth: 0,
	            //categories: [0,30,60,90, 120, 150, 180]
	            categories: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6"]
	        },
	        yAxis: {
	            reversed: false,
	            showFirstLabel: false,
	            //categories: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
	            min:0, 
	            max:100,
	            tickInterval: 25,
	            title: {
	                text: '',
	                reversed: false,
	            },
	            plotLines: [{
	                value: 0,
	                width: 0,
	                color: '#FF0000'
	            }]
	        },
	        tooltip: {
	            
	        },
	        legend: {
	            layout: 'vertical',
	            align: 'right',
	            verticalAlign: 'middle',
	            borderWidth: 0
	        },
	        series: series,
          
	    });
		//
	}

	setSeries(index){
		this.plotDevelopmentalGraph([this.developmentalSeries[index]]);
	}

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile-assesement',
  templateUrl: './profile-assesement.component.html',
  styleUrls: ['../patient-profile/patient-profile.component.scss', './profile-assesement.component.scss']
})
export class ProfileAssesementComponent implements OnInit {

  id:any = '0';
  formid:any = '0';
  subid:any = '0';
  selectedForm:any = {status:0, questions:[]}
  selectedFormMeta:any = {}
  selectedFormResult:any = {}
  selectedSlNo:any = '00';
  showResult = 0;
  showSuggestions = 0;
  showReportGenerator = 0;
  goalLevel = 0;
  goalCategory = 0;
  libraryGoals:any = {status:0, goals:[]};
  reportTemplate:any = " <p> Loading report... </p> ";
  forms:any = [];
  limit:any = 100;
  selectedQuestion:any = {question_id:0, question:"", note:""}
  

  
  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.formid = this.route.snapshot.paramMap.get('formid');
    this.subid = this.route.snapshot.paramMap.get('subid');
    
    if(this.subid == '0' || this.subid == null){
      this.subid = 0;
    }


    this.loadForm();
    this.loadForms();
  }

  loadForm(){
    
    this.dataService.getSinglePatientForm(this.formid, this.id, this.subid).subscribe( (data: any[] )=> {
      this.selectedForm = data;
      
    });
  }
  
  loadForms(){
    this.dataService.getClientAssessments(this.id, this.limit ).subscribe( (data: any )=> {
      this.forms = data;
      data.screening.forEach(element => {
        if(element.form_id == this.formid){
          this.selectedSlNo = element.sl_no;
        }
      });
    });
  }

  submitRadioResponse(question_id, option_id, i, j){
    
    if(this.selectedForm.questions[i].options[j].state == 1){
      this.selectedForm.questions[i].options[j].state = 0;
      
    } else {
      
      this.selectedForm.questions[i].options.forEach(element => {
        element.state = 0;
      });

      this.selectedForm.questions[i].options[j].state = 1;

      

    }

    let data = {
      patient_id:this.id,
      question_id: question_id,
      submission_id:this.subid,
      responses: [
        {
          option_id:option_id,
          state:this.selectedForm.questions[i].options[j].state
        }
      ]
    }

    this.dataService.postSubmitFormResponse(data).subscribe( (res:any) => {
      if(this.subid ==  0){
        this.subid = res.submission_id;
      }
      this.getFormProgress();
    });

    

    
  }

  saveForm(){
    alert( this.selectedForm.name + " saved");
    this.router.navigate(['/dashboard/client-assessment-review/'+this.id+'/'+this.formid+'/'+this.subid]);
  }

  getFormProgress(){
    this.dataService.getSinglePatientFormProgress(this.formid, this.id).subscribe( (data:any = {status:0, progress:0} )=> {
      this.selectedForm.progress = data.progress;
      
    });
  }

  selectQuestion(question){
    this.selectedQuestion = question;
  }

  submitNotes(data){
    data.patient_id = this.id;
    data.form_id = this.formid;
    data.submission_id = this.subid;
    data.question_id = this.selectedQuestion.question_id;
    console.log(data);

    this.dataService.postPfNotes(data).subscribe( (res:any) => {
      if(res.status == 0) {
        $('#modalRemarks').modal('hide');
        
        
      } else {
        alert(res.msg);
      }


    });

  }

  openTool(tool){
    if(tool.last_submitted_at != null){
      //review
      this.router.navigate(['/dashboard/client-assessment-review/'+this.id+'/'+tool.form_id]);
    } else {
      //new form
      this.router.navigate(['/dashboard/client-assessment/'+this.id+'/'+tool.form_id]);
    }
  }

  

}

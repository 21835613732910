import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {

  id = '0';
  client:any = {name:"", reg_no:"", address:"", phone:"", email:"", last_visit:"", age:"", dob:"", gender:""}
  appointments:any = {status:0, appointments:[]};
  appMode:any = 1;
  appLimit:any = 5;
  path:any = [];
  profileViewMode:any = 0;


  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    //this.loadAppointments();
    this.path = location.pathname.split("/");
    
    //@ts-ignore
    if(this.dataService.localData.roleId == 3 || this.dataService.localData.roleId == 4 || this.dataService.localData.roleId == 1){
      this.profileViewMode = 1;
    } else {
      this.profileViewMode = 2;
    }
    

  }

  loadAppointments(){
    this.dataService.getClientAppointments(this.id, this.appMode, this.appLimit).subscribe( (data: any[] )=> {
      this.appointments = data;
      
    });
  }  

  switchAppMode(mode){
    if(mode != this.appMode){
      this.appMode = mode;
      this.loadAppointments();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  date:any =new Date();
  data:any = {status:0}
  payments:any = [];
  creatingOrder:any = false;
  switching:any = false;
  switchingCycle:any = false;
  durations:any = [];
  selectedPaymentDuration:any = {id:0}

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {

    this.loadData();
    this.loadPayments();
    this.loadRenewalDurations();

    $( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();
          this.loadDate(dateText);

			    }
			});

			
		});
  }

  loadDate(date){

    if(date !== null){
    
      this.date = date;
    
    }

    
  }

  loadData(){
  	this.dataService.getOrgProfile().subscribe( (data: any )=> {
	  	console.log(data)	;
      if(data.status == 0){
          this.data = data;
          
        }
	  		
	  	} );
  }

  resetRenewal(data){
  	this.dataService.getResetRenewal(data.date).subscribe( (data: any )=> {
	  	if(data.status){
          this.loadData();
          $(".btn-close-modal").trigger("click");
          this.dataService.localData.showSubscriptionPop = true;
      }
	  		
	  	} );
  }

  loadRenewalDurations(){
  	this.dataService.getOrgRenewalDurations().subscribe( (data: any )=> {
	  	
      if(data.status == 0){
        this.durations = data.durations;
        console.log(this.durations);
      }
      
    } );
	  		
	  	
  }

  loadPayments(){
  	this.dataService.getOrgPayments().subscribe( (data: any )=> {
	  	
      if(data.status == 0){
          this.payments = data.payments;
          
        }
	  		
	  	} );
  }

  createOrder_(){
    this.creatingOrder = true;
    let data = {
      amount: 0

    };
    this.dataService.postCreateRazorpayOrder(data).subscribe( (res:any) => {
      console.log("res", res);
      //redirect to payments page;
      this.creatingOrder = false;
      if(res.status == 0){
        this.router.navigate(['/dashboard/admin/payment/'+res.internal_order_id]);
      } else {
        alert(res.msg);
      }
      
    }); 
  }

  createRenewalOrder(){
    this.creatingOrder = true;
    let data = this.selectedPaymentDuration;
    this.dataService.postCreateRenewalOrderRazorpay(data).subscribe( (res:any) => {
      console.log("res", res);
      //redirect to payments page;
      this.creatingOrder = false;
      if(res.status == 0){
        $(".btn-close-modal").trigger("click");
        this.router.navigate(['/dashboard/admin/payment/'+res.internal_order_id]);
      } else {
        alert(res.msg);
      }
      
    }); 
  }

  submitBalance(data){
    this.creatingOrder = true;

    this.dataService.postCreateRazorpaygOrder(data).subscribe( (res:any) => {
      
      //redirect to payments page;
      this.creatingOrder = false;
      if(res.status == 0){
        $(".btn-close-modal").trigger("click");
        this.router.navigate(['/dashboard/admin/payment/'+res.internal_order_id]);
      } else {
        alert(res.msg);
      }
      
    }); 

  }

  switchPlanOrder(){
    this.switching = true;
    let data = {plan_id:this.data.recommended.id}
    console.log("data", data);
    this.dataService.postSwitchPlanOrder(data).subscribe( (res:any) => {
      
      //redirect to payments page;
      this.switching = false;
      if(res.status == 0){
        if(res.proceed_payment){
          this.router.navigate(['/dashboard/admin/payment/'+res.internal_order_id]);
        } else {
          alert(res.msg);
          this.loadData();
        }
        
      } else {
        alert(res.msg);
      }
      
    }); 

  }

  switchToAnnual(){
    var cnf = confirm("Are you sure you want to switch your billing cycle to annual?");

    if(cnf){
      this.switchingCycle = true;
      this.dataService.getSwitchToAnnual().subscribe( (data: any )=> {
        this.switchingCycle = false;
        if(data.status == 0){
          
          if(data.proceed_payment){
            this.router.navigate(['/dashboard/admin/payment/'+data.internal_order_id]);
          } else {
            alert(data.msg);
            this.loadData();
          }
            
        } else {
          alert(data.msg);
        }
          
        } );
    }
  }

  selectPaymentDuration(option){
    this.selectedPaymentDuration = option;
  }

}

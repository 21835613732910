import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
	data:any = {bills:[]};
	mode = "";
	search = "";
	limit = 20;
	role_id = localStorage.getItem('role_id');
	billForm: FormGroup;
  	billItems: FormArray;
  	selectedBill = {index:0, id:0};
	pendingBillCount:any = 0;

  	branches = [];
  	selectedBranch = "";
  	loadedBill = {
		  status:0, 
		  data:{
			  bill_no:"", 
			  patient:"",
			  phone:"",
			  city:"",
			  country:"",
			  bill_date:"",
			  consultant:"",
			  payment_mode:"",
			  contents:[],
			  total:"",
			}
		};
  	date1 = "";
  	date2 = "";
	logo:any = null;

	/* reports */
	selectedReportType:any = 2;
	labels:any = [];
  	selectedLabel:any = 0;
	consultants:any = [];

  	constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private formBuilder: FormBuilder) { }

	ngOnInit() {

		this.billForm = this.formBuilder.group({
	      item: '',
	      rate: '',
	      qty: '',
	      amount:0,
	      billItems:this.formBuilder.array([this.createBillItem()])
	    });



	    this.loadBills();
	    this.loadBranches();
		this.loadLogo();
		this.loadBillLabels();
		this.loadPendingBillCount();
		this.loadConsultants();

	    var ref = this;
	  	//@ts-ignore
	  	$( "#date1" ).datepicker({
	    	dateFormat: "dd-mm-yy",
	    	onSelect: function (dateText, inst) {
				
				ref.date1 = dateText;
				ref.loadBills();
		    }
		});

		//@ts-ignore
	  	$( "#date2" ).datepicker({
	    	dateFormat: "dd-mm-yy",
	    	onSelect: function (dateText, inst) {
				
				ref.date2 = dateText;
				ref.loadBills();
		    }
		});

	}

	loadLogo(){
		this.dataService.getLogoData().subscribe( (data: any = {status:0, logo_data:""} )=> {
			if(data.status == 0){
				this.logo = data.logo_data;
			}
			
			
		});
	}

	loadPendingBillCount(){
		this.dataService.getPendingBillCount().subscribe( (data: any = {status:0, logo_data:""} )=> {
			if(data.status == 0){
				this.pendingBillCount = data.pending_count;
			}
			
			
		});
	}

	

	loadBills(){
		this.dataService.getBills(this.limit, this.search, this.mode, this.selectedBranch, this.date1, this.date2).subscribe( (data: any[] )=> {
	  		this.data = data;
	  	});
	}

	loadConsultants(){
		this.dataService.getTherapists(this.selectedBranch).subscribe( (data: any )=> {
			if(data.status == 0){
				this.consultants = data.therapists;
			}
	  		
	  	});
	}

	loadBranches(){
	  	this.dataService.getBranches().subscribe( (data: any[] )=> {
	  		this.branches = data;
	  		
	  		
	  	});
	}

	setMode(mode){
		this.mode = mode;
		this.loadBills();
	}

	searchBills(search) {
		this.search = search;
		this.loadBills();
	}

	loadMore() {
		this.limit +=20;
		this.loadBills();
	}
	

	createBillItem(): FormGroup {
	    return this.formBuilder.group({
	      item: '',
	      rate: '',
	      qty: '',
	      amount:0,
	    })
	  }

	  addBillItem():void {
	    this.billItems = this.billForm.get('billItems') as FormArray;
	    this.billItems.push(this.createBillItem());
	  }

	  removeBillItem(index) {
	    this.billItems.removeAt(index);

	  }


	  submitBill(){
	  	$('#btn-submit').attr('disabled', 'true');
    	$('#btn-submit').text('Submitting');

	    var data = {"patient_id": $('.patient-details').attr('data-patient') , "billcontents":[]};
	    var bc = [];

	    $('.ab-row').each(function(){
	      
	      var row = {
	        "description":$(this).find('.ab-item').val(),
	        "rate": $(this).find('.ab-rate').val(),
	        "qty": $(this).find('.ab-qty').val(),
	      }

	      bc.push(row);
	    });

	    data.billcontents = bc;
	    
	    this.dataService.postBill(data).subscribe( (res:any) => {

	      if(res.status == 0) {
	        alert(res.msg);
	        $('#modalBilling').modal('hide');

	        $('#btn-submit').removeAttr('disabled');
	        $('#btn-submit').text('Submit');
	        //$('#formBill')[0].reset();

	        this.loadBills();
	      } else {
	        alert(res.msg);
	      }

	    } );  
	    


	  }

	  markBillPaid(bill) {
		this.selectedBill = bill;
		$("#modal-payment").modal('show');
	  }

	  submitPaymentMode(data){
		if(data.mode != 0){
			data.bill_id = this.selectedBill.id;
			this.dataService.postMarkBillPaid(data).subscribe( (res:any) => {
			if(res.status == 0) {
			  	alert(res.msg);
				
				this.loadBills();
				$("#modal-payment").modal('hide');
			} else {
			  alert(res.msg);
			}
			} );
		} else {
			alert("Select a mode of payment to continue.");
		}
	  }

	  showPatientSelect() {
	  	$('.patient-details').hide();
		$('.patient-details').attr('data-patient', 0);
		$('.input-select-patient').val('');
		$('.input-select-patient').show();
	  }



	  switchBranch(branch) {
	    this.selectedBranch = branch;
	    this.loadBills();
	  }

	  viewBill(id) {
	  	this.loadedBill.status == 2;
	  	this.dataService.getSingleBill(id).subscribe( (data: any[] )=> {
	  		//@ts-ignore
	  		this.loadedBill = data;
	  		$('#modal-view-bill').modal('show');
	  		
	  	});

	  }

	  selectBill(bill) {
	  	this.selectedBill = bill;
	  }

	  submitBillCancel(data) {
	  	//@ts-ignore
	  	data.bill_id = this.selectedBill.id;

	  	this.dataService.postCancelBill(data).subscribe( (res:any) => {

	      if(res.status == 0) {
	        alert(res.msg);
	        $('#modal-cancel-bill').modal('hide');
			$(".btn-close-modal").trigger("click");
	        
	        this.loadBills();
	      } else {
	        alert(res.msg);
	      }

	    } ); 


	  }

	  setTbcBill(id){
		  this.dataService.getSingleBill(id).subscribe( (data: any[] )=> {
	  		//@ts-ignore
	  		this.loadedBill = data;
	  		this.printTbcBill();
	  		
	  	});
		  
	  }

	  printTbcBill(){
		//@ts-ignore
		var doc = new jsPDF();
		var y = 10;
		var yr = 30;

		//Logo
		doc.addImage(this.logo, 'PNG', 75,y,63.5,19.3);
		y+=30; yr+=20;
		doc.setFontSize(10);
		doc.setFontType('bold');
		doc.text(100, y, 'Tax Invoice');
		y+=10;

		//from
		doc.setFontSize(8);
		doc.text(10,y, "Talking Brains Center FZE");
		y+=5;
		doc.setFontType('normal');
		doc.text(10,y, "Office 505, 5/F, Apricot Tower,");
		y+=5;
		doc.text(10,y, "Silicon Oasis,");
		y+=5;
		doc.text(10,y, "Dubai, UAE.");
		y+=5;
		doc.text(10,y, "Emirate: Dubai");
		y+=5;
		doc.text(10,y, "TRN: 100332404100003");

		//buyer
		y+=10;
		doc.text(10, y, "Buyer");
		y+=5;
		doc.setFontType('bold');
		doc.text(10, y, this.loadedBill.data.patient);
		doc.setFontType('normal');
		y+=5;
		doc.text(10,y, this.loadedBill.data.phone);
		y+=5;
		doc.text(10,y, "Emirate:");
		doc.text(35, y, this.loadedBill.data.city);
		y+=5;
		doc.text(10,y, "Country:");
		doc.text(35,y, this.loadedBill.data.country);
		y+=5;
		doc.text(10,y, "Place of Supply:");
		doc.text(35,y, "Dubai, UAE");

		//right side of header
		doc.setFontType('bold');
		doc.text(135, yr, "Invoice No.");
		doc.text(169, yr, "Dated");
		doc.setFontType('normal');
		yr+=5;
		doc.text(135, yr, this.loadedBill.data.bill_no.toString());
		doc.text(169, yr, this.loadedBill.data.bill_date);
		
		yr+=10;
		doc.setFontType('bold');
		doc.text(135, yr, "Therapist");
		doc.text(169, yr, "Mode of Payment");
		doc.setFontType('normal');
		yr+=5;
		doc.text(135,yr, this.loadedBill.data.consultant);
		doc.text(169, yr, this.loadedBill.data.payment_mode);

		//particulars

		y+=10;
		doc.setFontType('bold');
		doc.text(10,y, "Sl No.");
		doc.text(26, y, "Particulars");
		doc.text(121, y, "Quantity", 'right');
		doc.text(140, y, "Rate", 'right');
		doc.text(159, y, "Amount", 'right');
		doc.text(185, y, "VAT %", 'right');

		doc.setFontType('normal');
		y+=7;
		var counter = 1;
		this.loadedBill.data.contents.forEach(element => {
			doc.text(10,y, counter.toString());
			doc.text(26, y, element.description);
			doc.text(121, y, element.qty.toString(), 'right' );
			doc.text(140, y, element.rate.toString(), 'right');
			doc.text(159, y, element.amount.toString(), 'right');
			doc.text(185, y, "0%", 'right');	
			y+=5;
			counter++;
		});

		
		
		
		

		//Total;

		doc.setFontType('bold');
		y+=20;
		doc.text(121, y, "Total", 'right');
		doc.setFontSize(10);
		doc.text(159, y, "AED "+this.loadedBill.data.total.toString(), 'right');

		doc.setFontType('normal');
		doc.setFontSize(8);

		y+=10;
		doc.text(10, y, "Amount chargeable (in words)");
		y+=5;
		doc.setFontSize(9);
		doc.setFontType('bold');
		//@ts-ignore
		doc.text(10, y, "UAE Dirham "+number2words(this.loadedBill.data.total)+" Only (AED " +this.loadedBill.data.total.toString() + ")"  );
		
		


		//output
		var string = doc.output('datauristring');
		var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
		newWindow.document.write(
		'<html><body><center>' +
		
		'</center><br>' +
		'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
		'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
		'</object></body></html>');
		newWindow.window.focus();

	  }

	submitReport(data){
		console.log(data.bill_status);
		var key = localStorage.getItem("key");
		window.open(this.dataService.REST_API_SERVER 
			+ "reports/index&report="+data.mode+"&date1="+data.date1+"&date2="+data.date2+"&bill_type="+data.bill_type
			+"&consultant_id="+data.consultant_id+"&bill_status="+data.bill_status
			+"&key="+key
			
			);
	}

	loadBillLabels(){
		this.dataService.getBillLabels().subscribe( (data: any  )=> {
		  this.labels = data
		  
		  
		});
	  }

}

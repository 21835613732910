import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-ads-form',
  templateUrl: './ads-form.component.html',
  styleUrls: ['../../patient-profile/patient-profile.component.scss', './ads-form.component.scss']
})
export class AdsFormComponent implements OnInit {

  @Input('id') id:any;
  @Input('formid') formid:any;
  @Input('subid') subid:any;
  formType:any = 5;
  selectedForm:any = {status:0, questions:[]}
  symptoms:any = [];
  reportBody:any = "";
  generateEnabled:any = false;
  reportGenerating:any = false;


  constructor(private dataService: DataService) { }

  ngOnInit() {
    
    if(this.subid == '0' || this.subid == null){
      this.subid = 0;
    }

    this.loadForm();
    this.loadSymptoms();
    this.loadReportGeneratorStatus();
  }
  loadForm(){
    
    this.dataService.getSinglePatientForm(this.formid, this.id, this.subid).subscribe( (data: any )=> {
      this.selectedForm = data;
      this.reportBody = data.reportBody;
    });
  }

  loadSymptoms(){
    this.dataService.getPatientSymptoms(this.id, this.formid).subscribe( (data: any[] )=> {
      this.symptoms = data;
      
    } );
  }

  loadReportGeneratorStatus(){
    this.dataService.getReportGeneratorStatus(this.formid).subscribe( (data: any )=> {
      if(data.status == 1){
        this.generateEnabled = true;
        this.dataService.localData.report_generator = 1;
      } else{
        this.generateEnabled = false;
      }
      
    } );
  }


  deleteSymptom(symptom){
    var cnf = confirm("Do you really want to delete " + symptom.symptom + "?");

    let data  = {id:symptom.id};

    if(cnf){
      this.dataService.postDeletePatientSymptom(data).subscribe( (res:any) => {
        if(res.status == 0){
          this.loadSymptoms();
        } else {
          alert(res.msg);
        }
      });
    }

  }

  submitSymptom(data) {
    
    $('#btn-add-symptom').attr('disabled', 'true');
    data.patient_id = this.id;
    data.form_id = this.formid;
    this.dataService.postAddPatientSymptom(data).subscribe( (res:any) => {
      $('#btn-add-symptom').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-symptom').modal('hide');
            $(".btn-close-modal").trigger("click");
            this.symptoms.push(res.data);
          } else {
            alert(res.msg);
          }
    }); 
    

  }

  submitReport(data) {
    
    $('#btn-add-report').attr('disabled', 'true');
    data.patient_id = this.id;
    data.form_id = this.formid;
    data.submission_id = this.subid;
    data.form_type = this.formType;
    this.dataService.postAddPatientReport(data).subscribe( (res:any) => {
      $('#btn-add-report').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-report').modal('hide');
            $(".btn-close-modal").trigger("click");
            alert(res.msg);
          } else {
            alert(res.msg);
          }
    }); 
    

  }

  generateReport(){
    this.reportBody = "Your report is being generated";
    this.reportGenerating = true;
    //$("#btn-add-report").attr("disabled", "true");
    

    
    this.dataService.getGeneratedReport(this.id, this.formid, this.subid).subscribe( (data: any )=> {
      //$('#btn-add-report').removeAttr('disabled');
      this.reportGenerating = false;
      if(data.status == 0){
        this.reportBody = data.report;
      } else{
        alert(data.msg);
      }
      
    } ); 


  }

}

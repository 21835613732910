import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-patients-referred',
  templateUrl: './patients-referred.component.html',
  styleUrls: ['./patients-referred.component.scss']
})
export class PatientsReferredComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any;
  limit = 20;
  search:any;
  from:any;
  date:any;
  branches:any;
  branch:any = {id:0, name:""};

  ngOnInit() {

    if(localStorage.getItem("branch") !== null){
			this.branch = JSON.parse( localStorage.getItem("branch"));
			
		}

  	this.data = [];
  	this.search="";
  	this.from = "";
  	this.date = "";
  	this.loadData();
  	this.loadBranches();
  }

  loadData() {
  	this.dataService.getPatientsReferred(this.limit, this.search, this.from, this.date, this.branch.id).subscribe( (data: any[] )=> {
	  		this.data = data;
	  		
  	} );
  }

  loadBranches() {
  	this.dataService.getBranches().subscribe( (data: any[] )=> {
	  		this.branches = data;
	  		
  	} );
  }

  filterBranch(id){
  	this.from = id;
  	this.loadData();
  }

  loadMore() {
	this.limit +=20;
	this.loadData();
  }

}

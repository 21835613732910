import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private formBuilder: FormBuilder) { }

  goals:any = {goals:[]};
  levels = [];
  categories = [];
  departments:any = {status:0, departments:[], mydept:"", mydeptId:0}
  selectedDept:any = {id:0, name:"View all"}
  selectedLevel = {"id":0, "level":""};
  selectedCategory = {"id":0, "level":""};
  selectedGoal = {"id":0};
  subGoals:any = {activities:[]};
  steps = [];
  selectedStep = {};
  tutorials = [];
  video = {};
  noVideoText = "Loading video";
  requirements = [];
  stats = [];
  selectedActivity = {};
  libraryMode = 1; // 1- in-clinic, 2- homeprogram;
  selectedGoalDept:any = 0;

  //upload
  file: File = null;
  uploadForm: FormGroup;
  error:string;
  fileUpload = {status: '', message: '', filePath: ''};
  upFiles = [];
  fileTitle = '';
  selectedTask:any = {id:0};

  ngOnInit() {

  	this.loadCategories();
  	this.loadLevels();
  	//this.loadGoals();
    //this.loadStats();
    this.loadDepartments();

    this.uploadForm = this.formBuilder.group({
      file: [''],
      file_title: "",
    });

    //stop video playback on modal close
    $('#modal-player').on('hidden.bs.modal', function (e) {
      //@ts-ignore
      $('#modal-player video').attr("src", '');
      //@ts-ignore
      this.video = {};
      //@ts-ignore
      this.noVideoText = "Loading video";

    });

  	

  }

  loadDepartments(){
    this.dataService.getDepts2().subscribe( (data:any )=> {
      this.departments = data
      if(data.mydeptId != 0){
        this.selectDept({id:data.mydeptId, name:data.mydept});
      } else {
        this.loadGoals();
      }
    } );
  }

  selectDept(dept){
    this.selectedDept = dept;
    this.selectedGoalDept = dept.id;
    this.loadGoals();
  }

  loadGoals() {

    

    if(this.libraryMode == 1){

      this.dataService.getGaLibraryGoals(this.selectedLevel.id, this.selectedCategory.id, this.selectedDept.id, 2).subscribe( (data: any[] )=> {
	  		this.goals = data;
	  		
	    } );

    } else {
      //homeprogram
      this.dataService.getGoals(this.selectedLevel.id, this.selectedCategory.id).subscribe( (data: any[] )=> {
	  		this.goals = data;
	  		
	    } );
    }

  	
  }

  loadCategories() {

    if(this.libraryMode == 1){
      this.dataService.getGaCategories().subscribe( (data: any[] )=> {
	  		this.categories = data;
	  		//@ts-ignore
	  		if (data.categories.length > 0) {
	  			//@ts-ignore
	  			this.selectedCategory = data.categories[0];

	  		}
	  		
	    } );
    } else {
      this.dataService.getGoalCategories().subscribe( (data: any[] )=> {
	  		this.categories = data;
	  		//@ts-ignore
	  		if (data.categories.length > 0) {
	  			//@ts-ignore
	  			this.selectedCategory = data.categories[0];

	  		}
	  		
	    } );
    }

  	
  }

  loadLevels() {

    if(this.libraryMode == 1) {
      this.dataService.getGaLevels().subscribe( (data: any[] )=> {
	  		this.levels = data;
	  		//@ts-ignore
	  		if (data.levels.length > 0) {
	  			//@ts-ignore
	  			this.selectedLevel = data.levels[0];
	  			
	  		}
	  		
	    } );
    } else {
      this.dataService.getGoalLevels().subscribe( (data: any[] )=> {
	  		this.levels = data;
	  		//@ts-ignore
	  		if (data.levels.length > 0) {
	  			//@ts-ignore
	  			this.selectedLevel = data.levels[0];
	  			
	  		}
	  		
	    } );  
    }

  	
  }


  selectGoal(goal) {

  	this.selectedGoal = goal;
  	this.subGoals = [];
    //load subGoals

    if(this.libraryMode == 1){
      this.dataService.getGaSubGoals(goal.id).subscribe( (data: any[] )=> {
	  		this.subGoals = data;
	  		console.log('subgoals', data);
	    } );
    } else {
      this.dataService.getSubGoals(goal.id).subscribe( (data: any[] )=> {
	  		this.subGoals = data;
	  		console.log('subgoals', data);
	    } );
    }

  	

  }


  selectLevel(index) {
  	//@ts-ignore
  	this.selectedLevel = this.levels.levels[index];
  	this.loadGoals();
  }

  selectCategory(index) {
  	//@ts-ignore
  	this.selectedCategory = this.categories.categories[index];
  	this.loadGoals();
  }

  submitGoal(data) {
    data.level_id = this.selectedLevel.id;
    data.category_id = this.selectedCategory.id;
    $('#btn-add-goal').attr('disabled', 'true');

    if(this.libraryMode == 1){
      this.dataService.postAddGa(data).subscribe( (res:any) => {
        $('#btn-add-goal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-goal').modal('hide');
              $(".btn-close-modal").trigger("click");
              this.loadGoals();
              
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddGoalLib(data).subscribe( (res:any) => {
        $('#btn-add-goal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-goal').modal('hide');
              this.loadGoals();
              
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  deleteGoal(goal) {

    var cnf = confirm("Are you sure you want to delete this goal?");
    var data = {"goal_id":goal.id  };

    if(this.libraryMode == 1){
      this.dataService.postDeleteGaGoal(data).subscribe( (res:any) => {
        if(res.status == 0) {
              this.loadGoals();
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postDeleteGoalLib(data).subscribe( (res:any) => {
        if(res.status == 0) {
              this.loadGoals();
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  editGoal(data) {
    $('#btn-edit-goal').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;

    if(this.libraryMode == 1){
      this.dataService.postEditGaGoal(data).subscribe( (res:any) => {
        $('#btn-edit-goal').removeAttr('disabled');
        if(res.status == 0) {
              this.loadGoals();
              $('#modal-edit-goal').modal('hide');
              $(".btn-close-modal").trigger("click");
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postEditGoalLib(data).subscribe( (res:any) => {
        $('#btn-edit-goal').removeAttr('disabled');
        if(res.status == 0) {
              this.loadGoals();
              $('#modal-edit-goal').modal('hide');
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  addLevel() {
    var cnf = confirm("Do you really want to add a new level?");

    if (cnf) {

      if(this.libraryMode == 1){
        this.dataService.postAddGaLevel().subscribe( (res:any) => {
        
          if(res.status == 0) {
                alert(res.msg);
                this.loadLevels();
              } else {
                alert(res.msg);
              }
        });
      } else {
        this.dataService.postAddGoalLevel().subscribe( (res:any) => {
        
          if(res.status == 0) {
                alert(res.msg);
                this.loadLevels();
              } else {
                alert(res.msg);
              }
        });
      }

      
    }

  }

  submitCategory(data) {
    
    $('#btn-add-category').attr('disabled', 'true');

    if(this.libraryMode == 1){
      this.dataService.postAddGaCategory(data).subscribe( (res:any) => {
        $('#btn-add-category').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-category').modal('hide');
              this.loadCategories();
              alert(res.msg);
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddGoalCategory(data).subscribe( (res:any) => {
        $('#btn-add-category').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-category').modal('hide');
              this.loadCategories();
              alert(res.msg);
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  submitSub(data) {
    $('#btn-add-subgoal').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;

    if(this.libraryMode == 1){
      this.dataService.postAddGaActivity(data).subscribe( (res:any) => {
        $('#btn-add-subgoal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-subgoal').modal('hide');
              $(".btn-close-modal").trigger("click");
              //@ts-ignore
              this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddActivity(data).subscribe( (res:any) => {
        $('#btn-add-subgoal').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-subgoal').modal('hide');
              //@ts-ignore
              this.dataService.getSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  loadSteps(goal_id) {
    this.dataService.getSteps(goal_id).subscribe( (data: any[] )=> {
        this.steps = data;      
        console.log('steps', this.steps);
        
    } );
  }

  loadRequirements(goal_id) {
    this.dataService.getRequirements(goal_id).subscribe( (data: any[] )=> {
        this.requirements = data;        
        //console.log(this.requirements);
    } );
  }

  submitStep(data) {
    $('#btn-add-step').attr('disabled', 'true');
    //@ts-ignore
    data.activity_id = this.selectedTask.id;

    this.dataService.postAddGoalActivityStep(data).subscribe( (res:any) => {
      $('#btn-add-step').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-step').modal('hide');
            $('.btn-cancel-step').click();
            $(".btn-close-modal").trigger("click");
            this.loadActivitySteps(this.selectedTask.id);
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  deleteStep(step_id) {

    var cnf = confirm("Are you sure you want to delete this step?");
    var data = {"id":step_id};

    this.dataService.postDeleteGoalActivityStep(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
            this.loadActivitySteps(this.selectedTask.id);
            
          } else {
            alert(res.msg);
          }
    });

  }

  selectStep(step){
    this.selectedStep = step;
    
  }

  submitReq(data) {
    $('#btn-add-req').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;



    this.dataService.postAddReq(data).subscribe( (res:any) => {
      $('#btn-add-req').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-req').modal('hide');
            
            this.loadRequirements(this.selectedGoal.id);
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  deleteReq(req_id) {
    var cnf = confirm("Are you sure you want to delete this item?");
    var data = {"id":req_id};

    this.dataService.postDeleteReq(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
            this.loadRequirements(this.selectedGoal.id);
            
          } else {
            alert(res.msg);
          }
    });
  }


  loadTutorials(goal_id) {
    this.dataService.getTutorials(goal_id).subscribe( (data: any[] )=> {
      this.tutorials = data;
      console.log('tuts', this.tutorials);
      
    });
  }

  loadStats() {
    this.dataService.getGoalStats().subscribe( (data: any[] )=> {
      this.stats = data;
      
      
    });
  }

  loadVideo(id){

    //@ts-ignore
    $('#modal-player').modal();

    this.dataService.getVideo(id).subscribe( (data: any[] ) =>{
        this.video = data;

        //@ts-ignore
        if (data.status == 1) {
          //@ts-ignore
          this.noVideoText = data.msg;
        }

    });
  }

  submitUpload() {
    
    const formData = new FormData();

    if(this.upFiles.length){
       for(let i=0 ; i < this.upFiles.length ; i++)
         formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
     }

    formData.append('file_title', this.fileTitle);
    //@ts-ignore
    formData.append('goal_id', this.selectedGoal.id);



    

    
    //@ts-ignore
    $('#btn-upload').text('Uploading');
    //@ts-ignore
    $('#btn-upload').attr('disabled', 'true');

    this.dataService.postUpload(formData).subscribe( (res:any) => {
      //@ts-ignore
        $('#btn-upload').text('Upload');
        //@ts-ignore
        $('#btn-upload').removeAttr('disabled');        
      
      if(res.status == 0) {
        
         alert(res.msg);
         //@ts-ignore
         $('#modal-upload').modal('hide');

         this.selectGoal(this.selectedGoal);
        
      } else {
        alert(res.msg);
      }

      

     });

   
  }

  onFileTitle(data) {
    this.fileTitle = data;
    
  }

  onFileSelect(event) {

    
    if(event.target.files.length > 0) {
      
      this.uploadForm.get('file').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }
      
    }
    

  }


  addNewTask(activity){
    this.selectedActivity = activity;
    $('#modal-add-task').modal("show");
  }

  submitTask(data) {
    //@ts-ignore
    data.activity_id = this.selectedActivity.activity_id;
    data.task_trials = 1;

    $('#btn-add-task').attr('disabled', 'true');

    if(this.libraryMode == 1) {
      this.dataService.postAddGaTask(data).subscribe( (res:any) => {
        $('#btn-add-task').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-task').modal('hide');
              $(".btn-close-modal").trigger("click");
              this.dataService.getGaSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    } else {
      this.dataService.postAddGoalTask(data).subscribe( (res:any) => {
        $('#btn-add-task').removeAttr('disabled');
          if(res.status == 0) {
              $('#modal-add-task').modal('hide');
              $(".btn-close-modal").trigger("click");
              this.dataService.getSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                  this.subGoals = data;
                  
              } );
              
              
            } else {
              alert(res.msg);
            }
      });
    }

    

  }

  changeLibMode(mode){
    this.libraryMode = mode;
    this.selectedLevel = {"id":0, "level":""};
    this.selectedCategory = {"id":0, "level":""};

    this.loadCategories();
  	this.loadLevels();
  	this.loadGoals();
  }


  selectTask(task){
    this.selectedTask = task;

    //Load steps
    this.loadActivitySteps(task.id);
  }

  loadActivitySteps(activity_id){
    this.dataService.getActivitySteps(activity_id).subscribe( (data: any[] )=> {
      this.steps = data;
      console.log(this.steps);
    } );
  }

  submitMaterial(data) {
    $('#btn-add-material').attr('disabled', 'true');
    //@ts-ignore
    data.step_id = this.selectedStep.id;

    this.dataService.postAddGoalActivityMaterial(data).subscribe( (res:any) => {
      $('#btn-add-material').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-material').modal('hide');
            $('.btn-close-modal').click();
            this.loadActivitySteps(this.selectedTask.id);
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  editTask(task){
    let input = prompt("Edit activity", task.task);
    if(input){
      
      let data = {id:task.id, task:input}
      this.dataService.postEditGoalActivity(data).subscribe( (res:any) => {
          console.log(res);
          if(res.status == 0) {
              task.task = input;
            } else {
              alert(res.msg);
            }
      });

    } 
  }


  editSubgoal(g){
    let input = prompt("Edit subgoal", g.activity_title);
    if(input){
      
      let data = {id:g.activity_id, subgoal:input}
      this.dataService.postEditSubgoal(data).subscribe( (res:any) => {
          
          if(res.status == 0) {
              g.activity_title = input;
            } else {
              alert(res.msg);
            }
      });

    } 
  }

  deleteSubgoal(g, index){
    let cnf = confirm("Do you really want to delete this subgoal?");
    if(cnf){
      
      let data = {id:g.activity_id}
      

      this.dataService.postDeleteSubgoal(data).subscribe( (res:any) => {
          
          if(res.status == 0) {
            //@ts-ignore
            this.subGoals.activities.splice(index, 1);
          } else {
            alert(res.msg);
          }
      }); 

    } 
  }

  deleteTask(task){
    let cnf = confirm("Do you really want to delete this activity?");
    if(cnf){
      
      let data = {id:task.id}
      
      this.dataService.postDeleteGoalActivity(data).subscribe( (res:any) => {
          
          if(res.status == 0) {
            this.selectedTask = {id:0};
            this.selectGoal(this.selectedGoal);
          } else {
            alert(res.msg);
          }
      });  

    } 
  }

  

}
